import { createSlice } from "@reduxjs/toolkit";

export const initialData = {
  isOpenLoginModal: false,
  isOpenSetAddressModal: false,
  isOpenSaveAddressModal: false,
  isOpenCartModal: false,
  isOpenBankModal: false,
  isOpenRiderContactModal: false,
  isOpenChangeCartModal: false,
  isOpenReOrderModal: false,
  isOpenReceiptModal: false,
  isOpenShareEarnModal: false,
  isOpenEditKocModal: false,
  isAnimationModal: false, 
  isOpenWithdrawalModal: false,
  isOpenPurchaseHistoryModal: false,
  showModal: false
};

const dataSlice = createSlice({
  name: "modal",
  initialState: initialData,
  reducers: {
    openLoginModal: (state, action) => {
      state.isOpenLoginModal = true;
    },
    openSetAddressModal: (state, action) => {
      state.isOpenSetAddressModal = true;
    },
    openSaveAddressModal: (state, action) => {
      state.isOpenSaveAddressModal = true;
    },
    openCartModal: (state, action) => {
      state.isOpenCartModal = true;
    },
    openBankModal: (state, action) => {
      state.isOpenBankModal = true;
    },
    openRiderContactModal: (state, action) => {
      state.isOpenRiderContactModal = true;
    },
    openChangeCartModal: (state, action) => {
      state.isOpenChangeCartModal = true;
    },
    openReOrderModal: (state, action) => {
      state.isOpenReOrderModal = true;
    },
    openReceiptModal: (state, action) => {
      state.isOpenReceiptModal = true;
    },
    openShareEarnModal: (state, action) => {
      state.isOpenShareEarnModal = true;
    },
    openEditKocModal: (state, action) => {
      state.isOpenEditKocModal = true;
    },
    openWithdrawalModal: (state, action) => {
      state.isOpenWithdrawalModal = true;
    },
    openPurchaseHistoryModal: (state, action) => {
      state.isOpenPurchaseHistoryModal = true;
    },
    closeLoginModal: (state, action) => {
      state.isOpenLoginModal = false;
    },
    closeSetAddressModal: (state, action) => {
      state.isOpenSetAddressModal = false;
    },
    closeSaveAddressModal: (state, action) => {
      state.isOpenSaveAddressModal = false;
    },
    closeCartModal: (state, action) => {
      state.isOpenCartModal = false;
    },
    closeBankModal: (state, action) => {
      state.isOpenBankModal = false;
    },
    closeRiderContactModal: (state, action) => {
      state.isOpenRiderContactModal = false;
    },
    closeChangeCartModal: (state, action) => {
      state.isOpenChangeCartModal = false;
    },
    closeReOrderModal: (state, action) => {
      state.isOpenReOrderModal = false;
    },
    closeReceiptModal: (state, action) => {
      state.isOpenReceiptModal = false;
    },
    closeShareEarnModal: (state, action) => {
      state.isOpenShareEarnModal = false;
    },
    closeEditKocModal: (state, action) => {
      state.isOpenEditKocModal = false;
    },
    closeWithdrawalModal: (state, action) => {
      state.isOpenWithdrawalModal = false;
    },
    closePurchaseHistoryModal: (state, action) => {
      state.isOpenPurchaseHistoryModal = false;
    },
    closeAllModal: (state, action) => {
      state.isOpenLoginModal = false;
      state.isOpenSetAddressModal = false;
      state.isOpenSaveAddressModal = false;
      state.isOpenCartModal = false;
      state.isOpenBankModal = false;
      state.isOpenRiderContactModal = false;
      state.isOpenChangeCartModal = false;
      state.isOpenReOrderModal = false;
      state.isOpenReceiptModal = false;
      state.isOpenShareEarnModal = false;
      state.isOpenEditKocModal = false;
      state.isOpenWithdrawalModal = false;
      state.isOpenPurchaseHistoryModal = false;
    },
    // used for modalbox animation
    setAnimationModal: (state, action) => {
      state.isAnimationModal = action.payload;
    },
    setShowModal: (state, action) => {
      state.showModal = action.payload;
    },
  },
  extraReducers: {
  },
});

export const { 
    openLoginModal, 
    openSetAddressModal,
    openSaveAddressModal,
    openCartModal,
    openBankModal,
    openRiderContactModal,
    openChangeCartModal,
    openReOrderModal,
    openReceiptModal,
    openShareEarnModal,
    openEditKocModal,
    openWithdrawalModal,
    openPurchaseHistoryModal,
    closeLoginModal, 
    closeSetAddressModal,
    closeSaveAddressModal,
    closeCartModal,
    closeBankModal,
    closeRiderContactModal,
    closeChangeCartModal,
    closeReOrderModal,
    closeReceiptModal,
    closeShareEarnModal,
    closeEditKocModal,
    closeWithdrawalModal,
    closePurchaseHistoryModal,
    closeAllModal,
    setAnimationModal,
    setShowModal,
} = dataSlice.actions;

export default dataSlice.reducer;
