import { configureStore } from "@reduxjs/toolkit";

//reducer
import commonReducer from "./reducer/commonReducer";
import modalReducer from "./reducer/modalReducer";
import storeReducer from "./reducer/storeReducer";
import menuReducer from "./reducer/menuReducer";
import checkoutReducer from "./reducer/checkoutReducer";
import orderReducer from "./reducer/orderReducer";
import loginReducer from "./reducer/loginReducer";

const reducer = {
    common: commonReducer,
    modal: modalReducer,
    store: storeReducer,
    menu: menuReducer,
    checkout: checkoutReducer,
    order: orderReducer,
    login: loginReducer,
}

const store = configureStore({
    reducer: reducer,
    devTools: process.env.REACT_APP_DEV_TOOL,
})

export default store