//lib
import { MDBRow } from "mdb-react-ui-kit";

//assets

//reducers

//components

export default function SymbolLine() {
  return (
    <div className="common _symbol-line">
        <div className="line relative">
          <label>&#11045;</label>
        </div>
    </div>
  );
}
