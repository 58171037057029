//lib
import Lottie from "lottie-react";
import { MDBContainer, MDBRow } from "mdb-react-ui-kit";

//reducers
import { useDispatch, useSelector } from "react-redux";
import { DotsLoading } from "../../../../assets/lotties";
import { useState } from "react";
import useWindowDimensions from "../../../../components/hook/use.window.dimensions";
import { getWalletHistory, setCallWalletHistory, setNeedLoading } from "../../../../redux/reducer/orderReducer";
import { useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

export default function PayoutHistory() {
    const dispatch = useDispatch();
  const { width, height } = useWindowDimensions()
  const { walletHistory, walletHistoryLoading, callWalletHistory } = useSelector((state) => state.order);

  const [length, setLength] = useState(10);
  const [start, setStart] = useState(0);
  const [data, setData] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [showNothingMore, setShowNothingMore] = useState(false);

  const getDateTime = (dateTime) => {
    const dateObject = new Date(dateTime);

    const day = String(dateObject.getDate()).padStart(2, '0');
    const month = String(dateObject.getMonth() + 1).padStart(2, '0');
    const year = dateObject.getFullYear();
    const hours = String(dateObject.getHours()).padStart(2, '0');
    const minutes = String(dateObject.getMinutes()).padStart(2, '0');
  
    const formattedDateTime = `${day}/${month}/${year} ${hours}:${minutes}`;

    return formattedDateTime
  }   

  let scrollHeight = height - (width > 550 ? (height * 0.3) : (height * 0.15))

  const getMoreData = () => {
    dispatch(setNeedLoading(false))
    dispatch(getWalletHistory({
      length: length,
      start: start + 10
    }))
    .unwrap()
    .then((res) => {
      setStart(start + 10);

      let newData = [];
      for (var key in res.data.aaData) {
        newData.push({
          ...res.data.aaData[key],
        });
      }
      if (data.length + newData.length >= res.data.iTotalRecords) {
        setHasMore(false);
      }
      setData([...data, ...newData]);
    })
    .catch((ex) => {});
  }

  useEffect(() => {
    if(callWalletHistory) {
        setStart(0)
    }

    dispatch(getWalletHistory({
        length: length,
        start: callWalletHistory ? 0 : start,
    }))
    .unwrap()
    .then((res) => {
        dispatch(setCallWalletHistory(false))
        setData(res.data.aaData);
        if (data.length < res.data.iTotalRecords) {
            setHasMore(true)
        }
    })
    .catch((ex) => {});
  }, [callWalletHistory]);

  return (
    <MDBRow className="mt-5">
        <MDBContainer style={{ paddingBottom: '4em' }}>
            <div className="flex justify-between items-center">
                <div className="_label --payout-history">
                    Wallet History
                </div>
                <div className="_label --details">
                    Details
                </div>
            </div>
            {!walletHistoryLoading ?
                <InfiniteScroll
                    className={`--withdraw ${hasMore ? '' : 'unscrollable'}`}
                    dataLength={data.length}
                    height={`${scrollHeight}px`}
                    hasMore={hasMore}
                    next={() => {
                        getMoreData();
                    }}
                    loader={hasMore && <div className="text-center mt-5 infinite-loading-label">Loading..</div>}
                >
                    {data?.length > 0 && data.map((history, historyIndex) => (
                        <MDBRow className="mt-4">
                            <div className={`flex justify-between items-center payout-row ${historyIndex === data.length - 1 ? '--no-line' : ''}`}>
                                <div className={`text-start mb-3 ${width < 380 ? 'col-6' : ''}`}>
                                    <div className="_label --payout-label">{history.description} 
                                        {history.status !== 'active' && 
                                            <label className={`_label --payout-status --${history.status}`}>{history.status}</label>
                                        }
                                    </div>
                                    <div className="_label --payout-desc mt-2">at {getDateTime(history.created_at)}</div>
                                </div>
                                <div className={`_label --payout-price mb-3 ${width < 380 ? 'col-6' : ''}`}>
                                    {history.amount < 0 ? '-' : ''} RM {history.amount !== null ? `${Math.abs(parseFloat(history.amount)).toFixed(2)}` : '0.00'}
                                </div>
                            </div>
                        </MDBRow>             
                    ))}
                    {!hasMore && <div className="text-center mt-5 infinite-loading-label --nothing">Nothing more..</div>}
                </InfiniteScroll>
            : 
                <div className="lottie-loading flex justify-center">
                    <div>
                        <div className="lottie-frame flex justify-content-center w-100">
                            <div className="image-frame">
                                <Lottie animationData={DotsLoading} />
                            </div>
                        </div>
                    </div>
                </div>    
            }
        </MDBContainer>
    </MDBRow>
  );
}