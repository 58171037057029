import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiServices from "../../services/api.services";

export const getOrderList = createAsyncThunk('getOrderList', async (payload, thunkAPI) => {
  try {
    const response = await apiServices.getOrderList(payload)
    return response
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})

export const getPurchaseHistory = createAsyncThunk('getPurchaseHistory', async (payload, thunkAPI) => {
  try {
    const response = await apiServices.getPurchaseHistory(payload)
    return response
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})

export const getReceiptInfo = createAsyncThunk('getReceiptInfo', async (payload, thunkAPI) => {
  try {
    const response = await apiServices.getReceiptInfo(payload)
    return response
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})

export const getProfile = createAsyncThunk('getProfile', async (payload, thunkAPI) => {
  try {
    const response = await apiServices.getProfile(payload)
    return response
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})

export const getWalletHistory = createAsyncThunk('getWalletHistory', async (payload, thunkAPI) => {
  try {
    const response = await apiServices.getWalletHistory(payload)
    return response
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})

export const withdrawAmount = createAsyncThunk('withdrawAmount', async (payload, thunkAPI) => {
  try {
    const response = await apiServices.withdrawAmount(payload)
    return response
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})

export const getWalletBalance = createAsyncThunk('getWalletBalance', async (payload, thunkAPI) => {
  try {
    const response = await apiServices.getWalletBalance(payload)
    return response
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})

export const initialData = {
    kocForm: {
        name: '',
        phone_no: '',
        birthday: '',
        gender: '',
        city: '',
        bank: '',
        bank_account: '',
    },
    walletBalance: 0,
    orderList: [],
    purchaseList: [],
    receiptInfo: {},
    profileInfo: {},
    walletHistory: [],
    callWalletHistory: false,
    genderOptions: [
      {label: 'Male', value: 'male'},
      {label: 'Female', value: 'female'}
    ],
    backPurchaseList: false,
    receiptInfoLoading: false,
    profileInfoLoading: false,
    purchaseListLoading: false,
    walletHistoryLoading: false,
    orderListLoading: false,
    needLoading: true,
};

const dataSlice = createSlice({
  name: "order",
  initialState: initialData,
  reducers: {
    setBackPurchaseList: (state, action) => {
      state.backPurchaseList = action.payload;
    },
    setNeedLoading: (state, action) => {
      state.needLoading = action.payload;
    },
    clearOrderList: (state, action) => {
      state.orderList = [];
    },
    setCallWalletHistory: (state, action) => {
      state.callWalletHistory = action.payload;
    },
  },
  extraReducers: {
    [getOrderList.pending]: (state, action) => {
      const needLoading = state.needLoading

      state.orderListLoading = needLoading ? true : false
    },
    [getOrderList.fulfilled]: (state, action) => {
      state.orderListLoading = false
      state.needLoading = true
      state.orderList = action.payload.data
    },
    [getOrderList.rejected]: (state, action) => {
      state.orderListLoading = false
    },

    [getPurchaseHistory.pending]: (state, action) => {
      const needLoading = state.needLoading

      state.purchaseListLoading = needLoading ? true : false
    },
    [getPurchaseHistory.fulfilled]: (state, action) => {
      state.purchaseListLoading = false
      state.needLoading = true
      state.purchaseList = action.payload.data.aaData
    },
    [getPurchaseHistory.rejected]: (state, action) => {
      state.purchaseListLoading = false
    },
    [getReceiptInfo.pending]: (state, action) => {
      state.receiptInfoLoading = true
    },
    [getReceiptInfo.fulfilled]: (state, action) => {
      state.receiptInfoLoading = false
      state.receiptInfo = action.payload.data.result
    },
    [getReceiptInfo.rejected]: (state, action) => {
      state.receiptInfoLoading = false
    },

    [getProfile.pending]: (state, action) => {
      state.profileInfoLoading = true
    },
    [getProfile.fulfilled]: (state, action) => {
      state.profileInfoLoading = false
      state.profileInfo = action.payload.data
    },
    [getProfile.rejected]: (state, action) => {
      state.profileInfoLoading = false
    },

    [getWalletHistory.pending]: (state, action) => {
      const needLoading = state.needLoading

      state.walletHistoryLoading = needLoading ? true : false
    },
    [getWalletHistory.fulfilled]: (state, action) => {
      state.walletHistoryLoading = false
      state.needLoading = true
      state.walletHistory = action.payload.data.aaData
    },
    [getWalletHistory.rejected]: (state, action) => {
      state.walletHistoryLoading = false
    },

    [getWalletBalance.pending]: (state, action) => {
      state.loading = true
    },
    [getWalletBalance.fulfilled]: (state, action) => {
      state.loading = false
      state.walletBalance = action.payload.data
    },
    [getWalletBalance.rejected]: (state, action) => {
      state.loading = false
    },
  },
});

export const { 
  setBackPurchaseList,
  clearOrderList,
  setNeedLoading,
  setCallWalletHistory
} = dataSlice.actions;

export default dataSlice.reducer;

