import { useEffect } from "react";

//lib
import Lottie from "lottie-react";
import { MDBContainer } from "mdb-react-ui-kit";
import { useNavigate, useSearchParams } from "react-router-dom";

//helper
import scrollToTop from "../../helpers/scroll.to.top";

//hook
import useWindowDimensions from "../../components/hook/use.window.dimensions";

//reducers
import { useDispatch, useSelector } from "react-redux";
import { getCartCount } from "../../redux/reducer/checkoutReducer";
import { openEditKocModal, openLoginModal, openPurchaseHistoryModal, openReceiptModal, openShareEarnModal, openWithdrawalModal } from "../../redux/reducer/modalReducer";
import { getOrderList, getWalletHistory, getProfile, getPurchaseHistory, getReceiptInfo, getWalletBalance, setBackPurchaseList, setOrderListLoading, setNeedLoading } from "../../redux/reducer/orderReducer";

//modal
import Receipt from "./modals/receipt";
import Withdrawal from "./modals/withdrawal";
import EditKOCDetails from "./modals/edit.koc.details";
import PurchaseHistory from "./modals/purchase.history";
import ShareEarnProgram from "./modals/share.earn.program";

//components
import Login from "../Login";
import OrderInfo from "./components/order.info";
import OrderSteps from "./components/order.steps";
import Button from "../../components/element/button";
import OrderListing from "./components/order.listing";
import PageHeader from "../../components/common/page.header";
import ConfirmationModal from "../../components/common/confirmation.modal";
import { OrderLoading } from "../../assets/lotties";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { DefaultCenter, EmptyCart } from "../../assets/images";

//socket
import Pusher from "pusher-js";

// Pusher.logToConsole = true;

export default function Order() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { width } = useWindowDimensions();
  
  const [searchParams, setSearchParams] = useSearchParams();

  const { orderList, orderListLoading } = useSelector((state) => state.order)
  const { 
    isOpenWithdrawalModal, 
    isOpenShareEarnModal, 
    isOpenEditKocModal, 
    isOpenReceiptModal, 
    isOpenLoginModal, 
    isOpenPurchaseHistoryModal,
    isOpenChangeCartModal
  } = useSelector((state) => state.modal)

  const token = localStorage.getItem("token")
  const userId = localStorage.getItem("userId")
  const login = localStorage.getItem("login")
  
  const username = localStorage.getItem("username")
  const checkedUsername = username && username !== undefined && username.charAt(0) !== '6' ? `60${username}` : username;
  
  const openModal = searchParams.get("open")
  const openModalId = searchParams.get("id")

  let centerHeight = window.innerHeight - (width > 991 ? 290 : 250);

  const handleRedirect = () => {
    navigate(`/order?open=share_earn`)
  }

  //connect pusher 
  useEffect(() => {
    if ((userId && userId !== undefined) && (username && username !== undefined)) {
      const pusher = new Pusher(process.env.REACT_APP_SOCKET_API_KEY, {
        cluster: "ap1",
        authEndpoint: process.env.REACT_APP_SOCKET_API_URL,
        auth: {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            username: `+${checkedUsername}`,
          },
        },
      });

      const channel = pusher.subscribe("presence-user" + userId);

      var socketId = null;
      pusher.connection.bind("connected", () => {
        socketId = pusher.connection.socket_id;
      });

      channel.bind("order-status-update-event", (data) => {
        if (data) {
          dispatch(setNeedLoading(false))
          dispatch(getOrderList())
        }
      });

      return () => {
        channel.unbind("order-status-update-event");
        pusher.disconnect();
      };
    }
  }, []);

  //auto open modal
  useEffect(() => {
    //receipt modal
    if (searchParams.get("order_id") || openModal === "receipt") {
      dispatch(openReceiptModal())
      navigate(`/order?open=receipt&id=${searchParams.get("order_id") || openModalId}`)

      dispatch(getReceiptInfo({
        order_id: searchParams.get("order_id") || openModalId
      }))
    }

    //purchase history modal
    if(openModal === "purchase_history") {
      
      if(login && login !== undefined && login === 'full') {
        dispatch(openPurchaseHistoryModal())
        dispatch(setBackPurchaseList(true))
      } else {
        dispatch(openLoginModal())
      }
    }

    //share and earn, edit kol and withdrawal modal
    if(openModal === "share_earn" || openModal === "edit_kol" || openModal === "withdrawal") {
      if(login && login !== undefined && login === 'full') {
        if(openModal === "share_earn") {
          dispatch(openShareEarnModal())
        } else if (openModal === "edit_kol") {
          dispatch(openEditKocModal())
        } else if (openModal === "withdrawal") {
          dispatch(openWithdrawalModal())
        }

        dispatch(getProfile())
        .unwrap()
        .then((res) => {
            dispatch(getWalletBalance())
            // dispatch(getWalletHistory())
        })
        .catch((ex) => {
            if (ex && ex.response.status === 422) {
                dispatch(openLoginModal())
            }
        });

      } else {
        dispatch(openLoginModal())
      }
    }
  }, [openModal, login, searchParams])
  
  useEffect(() => {
    // call order listing
    if(token) {
      dispatch(getOrderList())
    }
    
    // call cart counts
    dispatch(getCartCount())
  }, [])
  
  useEffect(() => {
    scrollToTop()
  }, [])

  return (
    <>
      <MDBContainer className="order-page-container" style={{ height: orderList?.length > 0 ? '' : width > 991 ? '95%' : '85%', paddingBottom: orderList?.length > 0 ? '' : 0 }}>
        <PageHeader icon="icon-park-outline:history-query" type="order" redirect>My Order</PageHeader>
        {!orderListLoading ?
          orderList?.length > 0 ? orderList.map((order, orderIndex) => (
            <>
              <OrderInfo order={order} />
              <OrderSteps order={order} index={orderIndex} />
              <OrderListing list={order.order} />
            </>
          )) 
          : 
          <div className="lottie-loading flex justify-center" style={{ height: `${centerHeight}px`, paddingTop: width > 770 ? '15em' : '8em' }}>
            <div>
              <div className="lottie-frame --order-empty flex justify-content-center w-100">
                <div className="image-frame">
                  <LazyLoadImage
                    src={EmptyCart}
                  />
                </div>
              </div>
              <div className="loading-label --empty text-center">
                No Data Available
              </div>
            </div>
          </div>
        : 
          <div className="lottie-loading flex justify-center" style={{ height: `${centerHeight}px`, paddingTop: width > 770 ? '15em' : '8em' }}>
            <div>
              <div className="lottie-frame flex justify-content-center w-100">
                <div className="image-frame">
                  <Lottie animationData={OrderLoading} />
                </div>
              </div>
              <div className="loading-label text-center">
                Loading..
              </div>
            </div>
          </div>
        }
        {!orderList?.length === 0 && <div style={{ height: `${centerHeight}px`}} />}
        <div className="button-container --blue" style={{ position: orderList?.length > 0 ? 'sticky' : 'relative', marginTop: '2em' }}> 
          <Button 
            className="w-100"
            onClick={() => handleRedirect()} 
          >
              Join Member Program
          </Button>
        </div>
      </MDBContainer>
      {isOpenShareEarnModal && <ShareEarnProgram />}
      {isOpenEditKocModal && <EditKOCDetails />}
      {isOpenWithdrawalModal && <Withdrawal />}
      {isOpenPurchaseHistoryModal && <PurchaseHistory />}
      {isOpenReceiptModal && <Receipt />}
      {isOpenLoginModal && <Login />}
      {isOpenChangeCartModal && <ConfirmationModal />}
    </>
  );
}
