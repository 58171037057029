import { useEffect, useRef, useState } from "react";

//lib
import Lottie from "lottie-react";
import { useNavigate } from "react-router-dom";
import { MDBContainer, MDBModalBody, MDBRow } from "mdb-react-ui-kit";

//helper
import scrollToTop from "../../../helpers/scroll.to.top";

//hook
import useWindowDimensions from "../../../components/hook/use.window.dimensions";

//reducers
import { useDispatch, useSelector } from "react-redux";
import { getPurchaseHistory, getReceiptInfo, setBackPurchaseList, setNeedLoading } from "../../../redux/reducer/orderReducer";
import { closePurchaseHistoryModal, openReceiptModal, setAnimationModal } from "../../../redux/reducer/modalReducer";

//components
import FullModal from "../../../components/modal/full.modal.box";
import ModalHeader from "../../../components/header/modal.header";
import { DotsLoading } from "../../../assets/lotties";
import InfiniteScroll from "react-infinite-scroll-component";

export default function PurchaseHistory() {
    const modalRef = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { width } = useWindowDimensions();
    const { height } = useWindowDimensions()
    
    const [ top, setTop ] = useState();
    
    const [length, setLength] = useState(10);
    const [start, setStart] = useState(0);
    const [data, setData] = useState([]);
    const [hasMore, setHasMore] = useState(false);
    const [showNothingMore, setShowNothingMore] = useState(false);
    
    const { purchaseList, purchaseListLoading } = useSelector((state) => state.order);
    const { isOpenPurchaseHistoryModal } = useSelector((state) => state.modal);
    
    let scrollHeight = height - (width > 550 ? 150 : (height * 0.05))

    const backButton = () => {
        navigate(`/order`)
        dispatch(closePurchaseHistoryModal())
        dispatch(setBackPurchaseList(false))
    }

    const handleOpenReceipt = (id) => {
        dispatch(setAnimationModal(false))
        dispatch(closePurchaseHistoryModal())
        dispatch(openReceiptModal())
        navigate(`/order?open=receipt&id=${id}`)

        dispatch(getReceiptInfo({
          order_id: id
        }))
    }

    const getMoreData = () => {
        dispatch(setNeedLoading(false))
        dispatch(getPurchaseHistory({
          length: length,
          start: start + 10
        }))
        .unwrap()
        .then((res) => {
          setStart(start + 10);

          let newData = [];
          for (var key in res.data.aaData) {
            newData.push({
              ...res.data.aaData[key],
            });
          }
          if (data.length + newData.length >= res.data.iTotalRecords) {
            setHasMore(false);
          }
          setData([...data, ...newData]);
        })
        .catch((ex) => {});
    }

    const handleScroll = () => {
        const scrollTop = document.documentElement.scrollTop;
        const windowHeight = window.innerHeight;
        const scrollHeight = document.documentElement.scrollHeight;
      
        if (scrollTop + windowHeight >= scrollHeight - 100) {
          setShowNothingMore(true);
        } else {
          setShowNothingMore(false);
        }
      };
      
    useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    
    return () => {
        window.removeEventListener("scroll", handleScroll);
    };
    }, []);

    useEffect(() => {
        dispatch(getPurchaseHistory({
          length: length,
          start: start,
        }))
        .unwrap()
        .then((res) => {
          setData(res.data.aaData);
          if (data.length < res.data.iTotalRecords) {
            setHasMore(true)
          }
        })
        .catch((ex) => {});
    }, []);
    

    useEffect(() => {
        setTop(true);
    }, [isOpenPurchaseHistoryModal]);
      
    useEffect(() => {
        if (top == true && modalRef.current) {
            scrollToTop(modalRef.current);
            setTop(false);
        }
    }, [top]);

    return (
        <FullModal
            centered
            scrollable
            staticBackdrop={true}
            show={isOpenPurchaseHistoryModal}
            backButton={backButton}
            screenSize={width >= 991 ? "xl" : "fullscreen-xl-down"}
            contentClassName="--white"
            content={
                <>
                    <ModalHeader title="Purchase History" backButton={backButton} backButtonNoAnimation={backButton} />
                    <MDBModalBody ref={modalRef}>
                      {!purchaseListLoading ?
                        <MDBContainer className="purchase-history-container"> 
                            <InfiniteScroll
                                dataLength={data.length}
                                height={`${scrollHeight}px`}
                                hasMore={hasMore}
                                next={() => {
                                    getMoreData();
                                }}
                                loader={hasMore && <div className="text-center mt-5 infinite-loading-label">Loading..</div>}
                            >
                                {data?.length > 0 && data.map((purchase, purchaseIndex) => (
                                    <MDBRow className="mt-4 pointer" onClick={() => handleOpenReceipt(purchase.order_id)} >
                                        <div className={`flex justify-between items-center purchase-row ${purchaseIndex === data.length - 1 ? '--no-line' : ''}`}>
                                            <div className="text-start mb-3">
                                                <div className="_label --purchase-label">{purchase.merchant_name}</div>
                                                <div className="_label --purchase-desc mt-2">at {purchase.created_at}</div>
                                                <div className="_label --purchase-desc mt-2">Receipt No.&nbsp;{purchase.receipt_no}</div>
                                            </div>
                                            <div className="_label --purchase-price mb-3">
                                                RM {parseFloat(purchase.total_price).toFixed(2)}
                                            </div>
                                        </div>
                                    </MDBRow>
                                ))}
                                {!hasMore && <div className="text-center mt-5 infinite-loading-label --nothing">Nothing more..</div>}
                            </InfiniteScroll>
                        </MDBContainer>
                      :
                        <div className="lottie-loading flex justify-center" style={{ paddingTop: '18em' }}>
                            <div>
                                <div className="lottie-frame --receipt flex justify-content-center w-100">
                                    <div className="image-frame">
                                        <Lottie animationData={DotsLoading} />
                                    </div>
                                </div>
                            </div>
                        </div>
                      }
                    </MDBModalBody>
                </>
            }
        />
    );
}