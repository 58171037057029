//lib
import { BrowserRouter, Routes, Route, Navigate, useLocation } from "react-router-dom";

//scss
import './scss/index.scss';
import './scss/components/_one-signal.scss';

//pages

//toast
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import "react-step-progress-bar/styles.css";

import 'swiper/css';
import 'swiper/css/effect-creative';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

//hook
import useWindowDimensions from "./components/hook/use.window.dimensions";

//utility
import { initializeOneSignal, observeNotificationChanges } from './utility/one_signal/OneSignalSetup';

//component
import MainLayout from "./components/layout/main.layout";
import Menu from "./pages/Menu";
import Store from "./pages/Store";
import Order from "./pages/Order";
import Checkout from "./pages/Checkout";
import { useEffect, useState } from "react";
import Policy from "./pages/Policy";

function ThemeColorHandler() {
  const { pathname } = useLocation();

  useEffect(() => {
    const metaThemeColor = document.querySelector("meta[name='theme-color']");
    metaThemeColor.setAttribute("content", "#fdfdff");
  }, [pathname]);

  return null;
}

function App() {
  // const [initialized, setInitialized] = useState(false);
  // const [notificationPermission, setNotificationPermission] = useState("default");

  // useEffect(() => {
  //   initializeOneSignal(setInitialized, setNotificationPermission);
  // }, []);
  
  // useEffect(() => {
  //   observeNotificationChanges(notificationPermission);
  // }, [notificationPermission]);

  return ( 
    <BrowserRouter>
      <ThemeColorHandler />
      <Routes>
        <Route 
          path="/"
          element={
            <Store />
          } 
        />
        <Route 
          path="/menu"
          element={
            <MainLayout>
              <Menu />
            </MainLayout>
          } 
        />
        <Route 
          path="/cart"
          element={
            <MainLayout>
              <Checkout />
            </MainLayout>
          } 
        />
        <Route 
          path="/order"
          element={
            <MainLayout>
              <Order />
            </MainLayout>
          } 
        />
        <Route 
          path="/policy"
          element={
            <MainLayout>
              <Policy />
            </MainLayout>
          } 
        />
        {/* used to auto open modal */}
        {/* <Route 
          path="/:state"
          element={
            <MainLayout>
              <Home />
            </MainLayout>
          } 
        /> */}
        {/* <Route 
          path="/post/:state"
          element={
            <MainLayout>
              <Home />
            </MainLayout>
          } 
        /> */}
        {/* handle 404 */}
        <Route path="*" element={<Navigate to="/" />} /> 
      </Routes>
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
