import { useEffect, useState } from "react";


//lib
import { MDBContainer } from "mdb-react-ui-kit";
import { useNavigate, useSearchParams } from "react-router-dom";

//assets

//reducers
import { useDispatch, useSelector } from "react-redux";
import {
  openSetAddressModal,
} from "../../redux/reducer/modalReducer";
import {
  getAddressList,
  getGuestToken,
  getOutletList,
} from "../../redux/reducer/storeReducer";
import { setCurrentLocation } from "../../redux/reducer/commonReducer";

//components
import OptionHeader from "./components/option.header";
import OutletListing from "./components/outlet.listing";
import PromoCarousel from "./components/promo.carousel";
import MainLayout from "../../components/layout/main.layout";
import SetAddress from "./modals/set.address";
import SaveAddress from "./modals/save.address";

//helper
import scrollToTop from "../../helpers/scroll.to.top";
import { getLocation } from "./../../helpers/locationHelper";

//hook
import useWindowDimensions from "../../components/hook/use.window.dimensions";

//css
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { NewYearGraphic, NewYearGraphic2, NewYearUp } from "../../assets/images";

export default function Store() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  
  const token = localStorage.getItem("token");
  const shipping = localStorage.getItem("shipping");
  const [activeToggle, setActiveToggle] = useState(shipping);

  const [searchParams, setSearchParams] = useSearchParams();
  const openModal = searchParams.get("open")

  const { currentLocation } = useSelector(
    (state) => state.common
  );
  const { outletList, addressList, outletLoading } = useSelector(
    (state) => state.store
  );
  const { isOpenSetAddressModal, isOpenSaveAddressModal } = useSelector(
    (state) => state.modal
  );

  //toggle shipping method
  const handleToggle = (value) => {
    if (value === "Delivery") {
      setActiveToggle(value);
      localStorage.setItem("shipping", "Delivery");

      navigate(`/?open=set_address`)
    } else {
      setActiveToggle(value);
      localStorage.setItem("shipping", "Pickup");
    }
  };

  //auto open modal
  useEffect(() => {
    if(openModal === 'set_address') {
      dispatch(openSetAddressModal())

      if(addressList.length === 0)
        dispatch(getAddressList())
      }
  }, [openModal])

  //get guest token first
  useEffect(() => {
    if (!token) {
      dispatch(getGuestToken());
    }
  }, [token]);

  useEffect(() => {
    scrollToTop()

    getLocation((location) => {
      dispatch(setCurrentLocation(location));
    });

    //set invitation code if got
    if (searchParams.get("invitation_code")) {
      localStorage.setItem('invitationCode', searchParams.get("invitation_code"))
    }
  }, []);

  //set shipping if redirect from webflow
  useEffect(() => {
    if (searchParams.get("shipping") === 'pickup') {
      setActiveToggle('Pickup')
      localStorage.setItem('shipping', 'Pickup')

      searchParams.delete("shipping")
      window.history.replaceState({}, document.title, `${window.location.pathname}`)
    } else if (searchParams.get("shipping") === 'delivery') {
      setActiveToggle('Delivery')
      navigate(`/?open=set_address`)
      localStorage.setItem('shipping', 'Delivery')

      searchParams.delete("shipping");
      window.history.replaceState({}, document.title, `${window.location.pathname}`)
    } else {
      setActiveToggle('Pickup')
      localStorage.setItem('shipping', 'Pickup')
    }
  }, [])

  useEffect(() => {
    dispatch(
      getOutletList({
        latitude: currentLocation.lat,
        longitude: currentLocation.lng,
      })
    );
  }, [currentLocation]);

  return (
    <>
      {width < 600 && <PromoCarousel />}
      <MainLayout>
        {/* <img src={NewYearUp} /> */}
        <MDBContainer className="store-page-container">
          <div className="store-content">
            <OptionHeader setOption={handleToggle} option={activeToggle} />
            <OutletListing
              outlets={outletList}
              loading={outletLoading}
              addresses={addressList}
              option={activeToggle}
            />
          </div>
        </MDBContainer>
        {/* {width < 600 && <img src={NewYearGraphic2} className="new-year-graphic"/>} */}
      </MainLayout>
      {isOpenSetAddressModal && <SetAddress addresses={addressList} />}
      {isOpenSaveAddressModal && <SaveAddress />}
    </>
  );
}
