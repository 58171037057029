import { useState } from "react";

//lib
import { Field, useFormikContext } from "formik";
import { MDBCol, MDBInput, MDBRow } from "mdb-react-ui-kit";
import { InputAdornment } from "@mui/material";
import { Icon } from "@iconify/react";

export default function Input(props) {
  const {
    label,
    type,
    name,
    disabled,
    placeholder,
    verification,
    className,
    handleVerification,
    as,
    autoFocus,
    inputMode,
    maxLength,
    onChange,
    isRequired,
    inputClassName,
    onClick,
    readOnly,
    onKeyDown, 
    ref
  } = props;

  const { values, errors, setFieldValue } = useFormikContext();

  const [inputFocus, setInputFocus] = useState(false);

  const handleFocus = () => {
    setInputFocus(true);
  };

  const handleBlur = () => {
    setInputFocus(false);
  };

  return (
    <>
    <MDBCol
      className={`
        element _text 
        ${errors && errors[name] ? '--error mb-0' : !as ? 'mb-5' : 'mb-4'} 
        ${className ? className : ''} 
      `}
      //change input focus border color
    >
      {label && (
        <MDBRow>
          <label>{isRequired && <span>* </span>}{label}</label>
        </MDBRow>
      )}
      <MDBRow
        className={`element _text ${ as ? `${as} ${inputClassName ? inputClassName : ''} ${disabled ? '--disabled' : ''}` : `mdb-field ${inputClassName ? inputClassName : ''}` }`}
        style={{ 
          border: 
          !values[name] && inputClassName !== 'phone_no' ?
              inputFocus && errors && errors[name] 
              ? "0.15rem solid #ff5c5c6b" 
              : inputFocus && !as ? "0.15rem solid #ffffff"
              : ''
          : !as ? '0.15rem solid transparent' : '',
          background: values[name] && as !== 'textarea'
          ? "rgba(0, 0, 0, 0.30)" 
          : "transparent"
        }}
        >
        {as === 'textarea' && inputClassName === 'special'?
          <Field
              onFocus={handleFocus}
              onBlur={handleBlur}

              type={type ? type : "text"}
              name={name}
              values={values[name]}
              placeholder={placeholder}
              disabled={disabled}
              as={'textarea'}

              className="focus"
              innerRef={autoFocus}
              inputMode={inputMode ? inputMode : ""}
              maxLength={maxLength ? maxLength : ''}
              onChange={onChange ? onChange : undefined} 
            />
        : as === 'withdraw-field' ?
          <Field
              onFocus={handleFocus}
              onBlur={handleBlur}

              type={type ? type : "text"}
              name={name}
              values={values[name]}
              placeholder={placeholder}
              disabled={disabled}
              className="focus"
              // innerRef={autoFocus}
              inputMode={inputMode ? inputMode : ""}
              maxLength={maxLength ? maxLength : ''}
              onChange={onChange ? onChange : undefined} 
              onKeyPress={onKeyDown ? onKeyDown : undefined}
          />
        : as ?
          inputClassName === 'phone_no' ?
            <div className="prefix">
              {inputClassName === 'phone_no' && <label>+60</label>}
              <Field
                  onFocus={handleFocus}
                  onBlur={handleBlur}

                  name={name}
                  disabled={disabled}
                  values={values[name]}
                  placeholder={placeholder}
                  type={type ? type : "text"}
                  as={as === 'textarea' ? as : ''}
                  className={inputClassName ? inputClassName : "focus"}
                  innerRef={autoFocus}
                  inputMode={inputMode ? inputMode : ""}
                  maxLength={maxLength ? maxLength : ''}
              />
            </div>
          : 
            <Field
                onFocus={handleFocus}
                onBlur={handleBlur}

                name={name}
                disabled={disabled}
                values={values[name]}
                placeholder={placeholder}
                type={type ? type : "text"}
                as={as === 'textarea' ? as : ''}
                className={inputClassName ? inputClassName : "focus"}
                innerRef={autoFocus}
                inputMode={inputMode ? inputMode : ""}
                maxLength={maxLength ? maxLength : ''}
            />
        : 
          <Field
            onFocus={handleFocus}
            onBlur={handleBlur}
            type={type ? type : "text"}
            name={name}
            values={values[name]}
            label={
              <label style={{ color: (values[name] || inputFocus) && !inputClassName ? '#333333' : '#9EBCD8', fontFamily: 'poppins-medium' }}>
                  {placeholder}
                  {isRequired && <span style={{ color: '#EE5B5B' }}> *</span>}
              </label>
            }
            disabled={disabled}
            as={MDBInput}
            className="focus"
            innerRef={autoFocus}
            inputMode={inputMode ? inputMode : ""}
            maxLength={maxLength ? maxLength : ''}
            onClick={onClick ? onClick : undefined} 
            readOnly={readOnly ? readOnly : ''}
          />
        }  
        {verification && (
          <div className="verification-button">
            <button type="button" onClick={() => handleVerification()}>
              SEND
            </button>
          </div>
        )} 
      </MDBRow>
    </MDBCol>
      {errors && errors[name] && as !== 'withdraw-field' ? (
        <div
          className="element _errors text-right no-padding error-message"
        >
          {errors[name]}
        </div>
      ) : null}
    </>
  );
}

