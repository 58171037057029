import { useEffect } from 'react'

//lib
import { MDBContainer } from 'mdb-react-ui-kit'
import TermsAndConditions from './components/terms.conditions'

export default function Policy() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <MDBContainer className="policy-container">
      <TermsAndConditions />
    </MDBContainer>
  )
}
