//lib
import { MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { LazyLoadImage } from "react-lazy-load-image-component";

//assets
import { DefaultCenter } from "../../../assets/images";

export default function OrderListing({ list }) {
    return (
      <MDBRow>
        <MDBContainer>
            {list?.length > 0 && list.map((item, itemIndex) => (
              <div className="flex mt-5 gap-3">
                <div className="p-0">
                    <div className="image-frame --order-food">
                        <LazyLoadImage src={item.picture || DefaultCenter} alt="..." />
                    </div>
                </div>
                <div className="w-100">
                    <label className="_label --order-food">
                        {item.food_name}
                    </label>
                    {item.details?.length > 0 && item.details.map((detail, detailIndex) => (
                        <div className="_label --order-addon">
                            {detail}
                        </div>
                    ))}
                </div>
                <div className="flex items-center">
                    <div className="quantity-circle">
                        x {item.quantity}
                    </div>
                </div>
              </div>
            ))}
        </MDBContainer>
      </MDBRow>
    );
  }