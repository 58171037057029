//lib

import { MDBContainer } from "mdb-react-ui-kit";
import { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Beverage, Christmas, Classic, Limited, RegularTop, Set, SingleTart, ToppingSet } from "../../../assets/images";
import { Skeleton } from "primereact/skeleton";
import useWindowDimensions from "../../../components/hook/use.window.dimensions";
import { useRef } from "react";

//assets

//reducers

//components

export default function CategoryBar({ withCategory, loading }) {
  const dispatch = useDispatch();
  const scrollingByCodeRef = useRef(false);
  const { width } = useWindowDimensions();

  const [activeTab, setActiveTab] = useState(null);
  const { categoryList } = useSelector((state) => state.menu);

  const categoryId =
    categoryList?.length > 0 ? categoryList.map((category) => category.id) : [];

  const categoryImages = [
    {
      image: Limited,
      alt: '...'
    },
    {
      image: ToppingSet,
      alt: '...'
    },
    {
      image: Set,
      alt: '...'
    },
    {
      image: SingleTart,
      alt: '...'
    },
    {
      image: Classic,
      alt: '...'
    },
    {
      image: Beverage,
      alt: '...'
    }
  ]

  useEffect(() => {
    function handleScroll() {
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const currentScroll = window.scrollY + windowHeight;
      const distanceFromBottom = documentHeight - currentScroll;

      if (width > 991 && distanceFromBottom <= 50) {
        const lastCategoryId = categoryId[categoryId.length - 1];
        setActiveTab(lastCategoryId);
      } else {
        categoryId?.length > 0 &&
        categoryId.forEach((id) => {
          const element = document.getElementById(`${id}`)
            ? document.getElementById(`${id}`)
            : 0;
          const elementItem = document.getElementById(`item-${id}`);
          if (element) {
            const elementPosition = element.getBoundingClientRect().top;
            const isVisible = elementPosition <= windowHeight * 0.3;
            if (isVisible && elementItem) {
              if (scrollingByCodeRef.current) {
                setTimeout(() => scrollingByCodeRef.current = false, 800);
              } else {
                setActiveTab(id);
              }
              elementItem.scrollIntoView({
                // behavior: "smooth",
                block: "nearest",
                inline: "center"
              });
            }
          }
        });
      }
    }
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <MDBContainer
      className={`category-container ${
        withCategory ? "--stick" : ""
      } mb-1 p-0`}
      style={{ marginTop: withCategory ? width > 991 ? '2.5em' : '1.5em' : '' }}
    >
      <div className="category-row">
        {!loading
          ? categoryList?.length > 0 &&
            categoryList.map((category, categoryIndex) => {

              const categoryImage = categoryImages[categoryIndex] ? categoryImages[categoryIndex].image : RegularTop;

              return (
                <div
                  className="category-tab"
                  key={categoryIndex}
                  id={`item-${category.id}`}
                  onClick={() => {
                    scrollingByCodeRef.current = true;

                    setActiveTab(category.id);
                    const clickedCategory = document.getElementById(`${category.id}`);
                    const targetPosition = clickedCategory.offsetTop;
                
                    window.scrollTo({
                      top: targetPosition - 180,
                      behavior: 'smooth',
                    });
                  }}
                >
                  <Link
                    // to={String(category.id)}
                    offset={-185}
                  >
                    {!withCategory && <div className="image-frame --category">
                      <LazyLoadImage
                        src={categoryImage}
                        alt=".."
                      />
                    </div>}
                    <label
                      className={activeTab === category.id ? "active pointer" : "pointer"}
                    >
                      {category.name}
                    </label>
                  </Link>
                </div>
              );
            })
          : Array.from({ length: 3}, (_, categoryTabIndex) => (
              <div className="category-tab">
                <div>
                    <Skeleton
                      width={width > 991 ? "16em" : '80%'}
                      height="4em"
                      borderRadius="1em"
                      className="blue-skeleton mt-2"
                    />
                </div>
              </div>
            ))}
      </div>
    </MDBContainer>
  );
}
