import { useEffect, useRef, useState } from "react";

//lib
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { MDBContainer, MDBModalBody, MDBRow } from "mdb-react-ui-kit";

//component
import Input from "../../../components/element/input";
import Button from "../../../components/element/button";
import MapComponent from "../../../components/common/map";
import FullModal from "../../../components/modal/full.modal.box";
import ModalHeader from "../../../components/header/modal.header";
import useWindowDimensions from "../../../components/hook/use.window.dimensions";

//helper
import scrollToTop from "../../../helpers/scroll.to.top";

//reducer
import { useDispatch, useSelector } from "react-redux";
import {
  clearAddressInfo,
  getAddressList,
  setAddressId,
  setFindAddress,
  setAddress,
  updateAddress,
} from "../../../redux/reducer/storeReducer";
import {
  closeSaveAddressModal,
} from "../../../redux/reducer/modalReducer";

export default function SaveAddress() {
  const formikRef = useRef();
  const modalRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const { pathname } = useLocation();

  const [top, setTop] = useState();
  const [search, setSearch] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [markerPlaced, setMarkerPlaced] = useState({});

  const { addressId, findAddress, addressInfo } = useSelector(
    (state) => state.store
  );
  const { isOpenSaveAddressModal } = useSelector(
    (state) => state.modal
  );

  function getAddressComponent(components, type) {
    const component = components.find((component) =>
      component.types.includes(type)
    );
    return component ? component.long_name : "";
  }

  const addressForm = {
    id: addressInfo.id,
    name: addressInfo.name ? addressInfo.name : "",
    unit:
      !findAddress?.address_components?.length > 0 && addressInfo.unit_no
        ? addressInfo.unit_no
        : getAddressComponent(
            findAddress?.address_components || [],
            "street_number"
          ) || "",
    street_name:
      !findAddress?.address_components?.length > 0 && addressInfo.street
        ? addressInfo.street
        : getAddressComponent(findAddress?.address_components || [], "route") ||
          "",
    city:
      !findAddress?.address_components?.length > 0 && addressInfo.city
        ? addressInfo.city
        : getAddressComponent(
            findAddress?.address_components || [],
            "locality"
          ) || "",
    postcode:
      !findAddress?.address_components?.length > 0 && addressInfo.postal_code
        ? addressInfo.postal_code
        : getAddressComponent(
            findAddress?.address_components || [],
            "postal_code"
          ) || "",
    state:
      !findAddress?.address_components?.length > 0 && addressInfo.state
        ? addressInfo.state
        : getAddressComponent(
            findAddress?.address_components || [],
            "administrative_area_level_1"
          ) || "",
    country:
      !findAddress?.address_components?.length > 0 && addressInfo.country
        ? addressInfo.country
        : getAddressComponent(
            findAddress?.address_components || [],
            "country"
          ) || "",
    notes: addressInfo.remark ? addressInfo.remark : "",
    address:
      !findAddress?.address_components?.length > 0 && addressInfo.address
        ? addressInfo.address
        : findAddress?.formatted_address
        ? findAddress.formatted_address
        : "",
    lat:
      !findAddress?.address_components?.length > 0 && addressInfo.latitude
        ? addressInfo.latitude
        : typeof findAddress?.geometry?.location?.lat === "function"
        ? findAddress.geometry.location.lat()
        : typeof findAddress?.geometry?.location?.lat === "number"
        ? findAddress.geometry.location.lat
        : 3.139,
    lng:
      !findAddress?.address_components?.length > 0 && addressInfo.longitude
        ? addressInfo.longitude
        : typeof findAddress?.geometry?.location?.lng === "function"
        ? findAddress.geometry.location.lng()
        : typeof findAddress?.geometry?.location?.lng === "number"
        ? findAddress.geometry.location.lng
        : 101.6869,
  };

  const useSchema = Yup.object({
    name: Yup.string().required("Address name is required"),
    unit: Yup.string().required("Unit or house number is required"),
    street_name: Yup.string(),
    city: Yup.string().required("City is required"),
    postcode: Yup.string().required("Postal code is required"),
    state: Yup.string().required("State is required"),
    country: Yup.string().required("Country is required"),
  });

  const backButton = () => {
    dispatch(closeSaveAddressModal());
    if(pathname === '/cart') {
      navigate('/cart?open=set_address')
    } else if (pathname === '/') {
      navigate('/?open=set_address')
    }
    dispatch(setFindAddress({}));
    dispatch(setAddressId(""));
    dispatch(clearAddressInfo());
  };

  const backToMain = () => {
    dispatch(closeSaveAddressModal());
    dispatch(setFindAddress({}));
    dispatch(setAddressId(""));
    dispatch(clearAddressInfo());
  };

  const handleSubmit = ({ values, setFieldError }) => {
    if (addressInfo.id) {
      editSubmit({ values, setFieldError });
    } else {
      addSubmit({ values, setFieldError });
    }
  };

  const addSubmit = ({ values, setFieldError }) => {
    setSubmitting(true);

    try {
      const addressData = {
        name: values.name,
        unit_no: values.unit,
        street: values.street_name,
        city: values.city,
        postal_code: values.postcode,
        state: values.state,
        country: values.country,
        remark: values.notes,
        address: `${values.unit}, ${values.street_name ?? ""} ${
          values.street_name ? "," : ""
        } ${values.city}, ${values.postcode} ${values.state} ${values.country}`,
        latitude: parseFloat(values.lat),
        longitude: parseFloat(values.lng),
      };

      dispatch(setAddress(addressData))
      .unwrap()
      .then((res) => {
        dispatch(getAddressList());
        setSubmitting(false);
        dispatch(closeSaveAddressModal());
        if(pathname === '/cart') {
          navigate('/cart?open=set_address')
        } else if (pathname === '/') {
          navigate('/?open=set_address')
        }
        toast.success("Save address successful.", {
          autoClose: 1500,
          theme: "light",
          hideProgressBar: true,
          closeOnClick: true,
          closeButton: false,
        });
      })
      .catch((ex) => {
        if (ex && ex.response.status === 422) {
          const errors = ex.response.data.errors;
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) => {
              setFieldError(item, errors[item]);
            });
            toast.error("Save address unsuccessful.", {
              autoClose: 1500,
              theme: "light",
              hideProgressBar: true,
              closeOnClick: true,
              closeButton: false,
            });
          }
        }
      });

    } catch (ex) {
      if (ex && ex.response.status === 422) {
        setSubmitting(false);
        const errors = ex.response.data.errors;
        if (errors && Object.keys(errors).length > 0) {
          Object.keys(errors).map((item, i) =>
            setFieldError(item, errors[item])
          );

        }
      }
    }
  };

  const editSubmit = ({ values, setFieldError }) => {
    setSubmitting(true);

    try {
      const updatedAddressData = {
        id: addressInfo.id,
        name: values.name,
        unit_no: values.unit,
        street: values.street_name,
        city: values.city,
        postal_code: values.postcode,
        state: values.state,
        country: values.country,
        remark: values.notes,
        address: `${values.unit}, ${values.street_name ?? ""} ${
          values.street_name ? "," : ""
        } ${values.city}, ${values.postcode} ${values.state} ${values.country}`,
        latitude: values.lat,
        longitude: values.lng,
      };

      dispatch(updateAddress(updatedAddressData)).then(() => {
        dispatch(getAddressList());
      });
      setSubmitting(false);
      dispatch(closeSaveAddressModal());
      if(pathname === '/cart') {
        navigate('/cart?open=set_address')
      } else if (pathname === '/') {
        navigate('/?open=set_address')
      }
      toast.success("Save address successful.", {
        autoClose: 1500,
        theme: "light",
        hideProgressBar: true,
        closeOnClick: true,
        closeButton: false,
      });
    } catch (ex) {
      if (ex && ex.response.status === 422) {
        setSubmitting(false);
        const errors = ex.response.data.errors;
        if (errors && Object.keys(errors).length > 0) {
          Object.keys(errors).map((item, i) =>
            setFieldError(item, errors[item])
          );
        }
      }
    }
  };

  const handleSearch = (value) => {
    setSearch(value);
  };

  const handleClear = (value) => {
    setSearch("");
  };

  const handleMarkerPlaced = (value) => {
    setMarkerPlaced({ lat: value.lat, lng: value.lng });
  };

  useEffect(() => {
    if (addressId) {
      const savedAddresses =
        JSON.parse(localStorage.getItem("savedAddresses")) || [];
    }

    if (formikRef.current && findAddress?.address_components === undefined) {
      formikRef.current.setFieldError(
        "street_name",
        "Drop the marker on the map to identify your location."
      );
    }
  }, [findAddress, formikRef]);

  useEffect(() => {
    setTop(true);
  }, [isOpenSaveAddressModal]);

  useEffect(() => {
    if (top == true && modalRef.current) {
      scrollToTop(modalRef.current);
      setTop(false);
    }
  }, [top, modalRef]);

  return (
    <FullModal
      centered
      scrollable
      staticBackdrop={true}
      show={isOpenSaveAddressModal}
      backButton={backToMain}
      screenSize={width >= 991 ? "xl" : "fullscreen-xl-down"}
      contentClassName=""
      content={
        <>
          <ModalHeader
            title="Save Address"
            backButton={backButton}
            backButtonNoAnimation={backButton}
          />
          <MDBModalBody ref={modalRef}>
            <Formik
              innerRef={formikRef}
              initialValues={addressForm}
              validationSchema={useSchema}
              enableReinitialize={true}
              onSubmit={(values, { errors, setFieldError }) => {
                handleSubmit({ values, errors, setFieldError });
              }}
            >
              {({
                isValid,
              }) => (
                <Form>
                  <MDBContainer className="save-address-container">
                    <label className="_label --info-title mt-0 ps-4">
                      Information Details
                    </label>
                    <MDBRow className="flex justify-center">
                      <div className="image-frame --map mt-3 mb-5">
                        <MapComponent
                          onMarkerPlaced={handleMarkerPlaced}
                          markerPlaced={markerPlaced}
                          findAddress={addressId ? addressInfo : findAddress}
                        />
                      </div>
                    </MDBRow>
                    <div className="p-2">
                      <Input
                        name="name"
                        label="Name"
                        placeholder="Save address as, e:g My House, Office"
                        as="round-field"
                        isRequired={true}
                        inputClassName="--grey"
                      />
                      <Input
                        name="unit"
                        label="Unit/House Number"
                        as="round-field"
                        isRequired={true}
                        inputClassName={
                          getAddressComponent(
                            findAddress?.address_components || [],
                            "street_number"
                          )
                            ? "--autofill"
                            : ""
                        }
                      />
                      <Input
                        name="street_name"
                        label="Street Name"
                        as="round-field"
                        isRequired={false}
                        disabled={true}
                        inputClassName={
                          getAddressComponent(
                            findAddress?.address_components || [],
                            "route"
                          )
                            ? "--autofill"
                            : ""
                        }
                      />
                      <Input
                        name="city"
                        label="City"
                        as="round-field"
                        isRequired={false}
                        disabled={true}
                        inputClassName={
                          getAddressComponent(
                            findAddress?.address_components || [],
                            "locality"
                          )
                            ? "--autofill"
                            : ""
                        }
                      />
                      <Input
                        name="postcode"
                        label="Postal Code"
                        as="round-field"
                        isRequired={false}
                        disabled={true}
                        inputClassName={
                          getAddressComponent(
                            findAddress?.address_components || [],
                            "postal_code"
                          )
                            ? "--autofill"
                            : ""
                        }
                      />
                      <Input
                        name="state"
                        label="State"
                        as="round-field"
                        isRequired={false}
                        disabled={true}
                        inputClassName={
                          getAddressComponent(
                            findAddress?.address_components || [],
                            "administrative_area_level_1"
                          )
                            ? "--autofill"
                            : ""
                        }
                      />
                      <Input
                        name="country"
                        label="Country"
                        as="round-field"
                        isRequired={false}
                        disabled={true}
                        inputClassName={
                          getAddressComponent(
                            findAddress?.address_components || [],
                            "country"
                          )
                            ? "--autofill"
                            : ""
                        }
                      />
                      <MDBRow>
                        <label className="_label --add-note mt-2">
                          Add Notes To Rider (Optional)
                        </label>
                        <div className="p-3 mt-3">
                          <div className="rider-note">
                            <Input
                              name="notes"
                              placeholder="Add delivery instructions, e.g.: nearest building, nearest place"
                              as="textarea"
                              isRequired={false}
                              maxLength={60}
                              inputClassName="--grey"
                            />
                          </div>
                        </div>
                      </MDBRow>
                      <div className="button-container --notFixed address">
                        <Button
                          type="submit"
                          className="w-100"
                          disabled={!isValid || submitting}
                        >
                          Save Address
                        </Button>
                      </div>
                    </div>
                  </MDBContainer>
                  {/* solve bug purpose */}
                  <div className="button-container hide" style={{ display: 'none' }}>
                    <Button
                    >
                      Save Address
                    </Button>
                  </div>
                  {/* solve bug purpose */}
                </Form>
              )}
            </Formik>
          </MDBModalBody>
        </>
      }
    />
  );
}
