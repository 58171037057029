import { useState, useEffect, useRef } from "react";
import { Icon } from "@iconify/react";
import { Skeleton } from "primereact/skeleton";
import { MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import { motion } from "framer-motion";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

//redux
import { useDispatch, useSelector } from "react-redux";
import { LazyLoadImage } from "react-lazy-load-image-component";
import useWindowDimensions from "../../../components/hook/use.window.dimensions";
import {
  openCartModal,
  openReOrderModal,
} from "../../../redux/reducer/modalReducer";
import { DefaultMenu } from "../../../assets/images";
import {
  getMenuInfo,
  setMenuId,
  setQuantity,
  setReorderInfo,
} from "../../../redux/reducer/menuReducer";
import { useNavigate } from "react-router-dom";

//component

export default function MenuListing({ menus, loading, carts }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();

  const [isLoading, setisLoading] = useState(true);

  let cartItems = [];

  carts?.cart?.length > 0 &&
    carts.cart.map((item) =>
      cartItems.push({
        id: item.menu_id,
        option: item.details,
        cart_item_id: item.cart_item_id,
        quantity: item.quantity,
      })
    );

  const handleSelectMenu = (menu) => {
    const isMenuInCart = cartItems.find((item) => item.id === menu.menu_id);

    if (menu.stock_status === 1 && isMenuInCart) {
      dispatch(openReOrderModal());
      dispatch(
        setReorderInfo({
          id: menu.menu_id,
          name: menu.menu_name,
          price: menu.unit_price,
          options: isMenuInCart.option,
          cart_id: isMenuInCart.cart_item_id,
          quantity: isMenuInCart.quantity,
        })
        );
    } else {
      dispatch(setQuantity(1));
      navigate(`/menu?open=add_cart&id=${menu.menu_id}`)
      dispatch(setMenuId(menu.menu_id));
    }
  };

  const groupByCategory =
    menus?.length > 0 &&
    menus.reduce((acc, item) => {
      
      if (!acc[item.category_id]) {
        acc[item.category_id] = {
          id: `${item.category_id}`,
          name: `${item.category_name}`,
          items: [],
        };
      }
      acc[item.category_id].items.push(item);
      return acc;
    }, {});
    
  const newMenus = Object.values(groupByCategory).flatMap((group) => [
    { id: group.id, cat_name: group.name },
    ...group.items,
  ]);

  return (
    <MDBContainer className="menu-food-container">
      <MDBRow>
        {!loading ? 
            newMenus?.length > 0 &&
            newMenus.map((menu, menuIndex) => {
              return (
                <MDBCol
                  key={menuIndex}
                  className={
                    menu.category_id
                      ? "col-6 col-md-3 p-6 menu-listing --food"
                      : "col-12 col-md-12 p-6 menu-listing"
                  }
                  id={`category-${menu.category_id ? menu.category_id : menu.id}`}
                >
                  <MDBRow className="mt-2 mb-0" id={menu.category_id}>
                    <label className="_label --category-name">
                      {menu.cat_name}
                    </label>
                  </MDBRow>
                  {menu.category_id ? (
                    <motion.div
                      animate={{ opacity: 0 }}
                      whileInView={{
                        opacity: 1,
                      }}
                      className="text-center"
                    >
                      <MDBContainer
                        className={`menu-listing-container ${ menu.stock_status === 0 ? "disabled" : "pointer" }`}
                        onClick={() => menu.stock_status === 1 ? handleSelectMenu(menu) : '' }
                      >
                        <MDBRow className="image-row flex justify-center relative">
                          <div className={`image-frame --food ${ menu.stock_status === 0 ? "out-stock disabled" : "pointer" }`}>
                            <LazyLoadImage
                              src={menu.picture || DefaultMenu} // || DefaultFood
                              alt="..."
                            />
                          </div>
                          {menu.stock_status === 0 && <span>Out Of Stock</span>}
                        </MDBRow>
                      </MDBContainer>
                      <MDBContainer 
                        className={`food-info ${ menu.stock_status === 0 ? "--out-stock" : "" }`}
                        onClick={() => menu.stock_status === 1 ? handleSelectMenu(menu) : '' }
                      >
                        <MDBRow>
                          <label className={`_label --food ${ menu.stock_status === 0 ? "disabled" : "pointer" }`}>
                            {menu.menu_name}
                          </label>
                        </MDBRow>
                        <MDBRow>
                          <label className={`_label --food mt-2 ${ menu.stock_status === 0 ? "disabled" : "pointer" }`}>
                            RM {parseFloat(menu.unit_price).toFixed(2)}
                          </label>
                        </MDBRow>
                      </MDBContainer>
                    </motion.div>
                  ) : (
                    ""
                  )}
                </MDBCol>
              );
            })
          : Array.from({ length: 4 }, (_, menuListIndex) => (
              <MDBCol className="col-6 col-md-3 p-6 menu-listing --food mt-5 pt-3">
                <MDBRow className="mt-2 mb-0"></MDBRow>
                <div>
                  <div className="w-100 flex justify-center">
                    <Skeleton
                      width="142px"
                      height="142px"
                      borderRadius="18px"
                      className="blue-skeleton"
                    />
                  </div>
                  <MDBContainer className="food-info">
                    <MDBRow className="w-100 flex justify-center ms-1">
                      <Skeleton width="50%" height="1.5em" className="blue-skeleton"/>
                    </MDBRow>
                    <MDBRow className="w-100 flex justify-center ms-1">
                      <Skeleton width="30%" height="1.5em" className="mt-4 blue-skeleton" />
                    </MDBRow>
                  </MDBContainer>
                </div>
              </MDBCol>
            ))}
      </MDBRow>
    </MDBContainer>
  );
}
