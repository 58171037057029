//lib
import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";

export default function PaymentInfo({ paymentInfo }) {
  return (
    <MDBRow className="text-start">
        <div className="_label --payment-info">Payment Info</div>
        <MDBContainer className="payment-info-container">
            <div className="flex items-center">
                <MDBCol className="col-6 p-0">
                    <label className="_label --payment-label">
                        Payment
                    </label>
                </MDBCol>
                <MDBCol className="col-6 text-end">
                    <label className="_label --payment-no">
                        {paymentInfo.payment_method === 'DuitNow' ? 'FPX' : paymentInfo.payment_method}
                    </label>
                </MDBCol>
            </div>
            <div className="flex items-baseline mt-3">
                <MDBCol className="col-6 p-0">
                    <label className="_label --payment-label">
                        Date and time
                    </label>
                </MDBCol>
                <MDBCol className="col-6 text-end">
                    <label className="_label --payment-no">
                        {paymentInfo.created_at}
                    </label>
                </MDBCol>
            </div>
        </MDBContainer>
    </MDBRow>
  );
}