import { GoogleMap, LoadScript, Marker  } from "@react-google-maps/api";
import { setFindAddress } from "../../redux/reducer/storeReducer";
import { useDispatch } from "react-redux";

const libraries = ["places"];

const MapComponent = ({ onMarkerPlaced, markerPlaced, findAddress }) => {
    const dispatch = useDispatch();

    const mapContainerStyle = {
        height: "100%",
        width: "100%",
        borderRadius: '1em'
    };

    const center = { 
      lat: findAddress.latitude ? parseFloat(findAddress.latitude) 
          : typeof findAddress?.geometry?.location?.lat === 'function'
          ? findAddress.geometry.location.lat()
          : (typeof findAddress?.geometry?.location?.lat === 'number'
              ? findAddress.geometry.location.lat
              : 3.1390), 
      lng: 
      findAddress.longitude ? parseFloat(findAddress.longitude)  
          : typeof findAddress?.geometry?.location?.lng === 'function'
          ? findAddress.geometry.location.lng()
          : (typeof findAddress?.geometry?.location?.lng === 'number'
              ? findAddress.geometry.location.lng
              : 101.6869),
  };

    const onMapClick = async (event) => {
        onMarkerPlaced({lat: event.latLng.lat(), lng: event.latLng.lng()});
        dispatch(setFindAddress(await reverseGeocode(event.latLng.lat(), event.latLng.lng())))
    };

    const reverseGeocode = async (lat, lng) => {
        let result = {}

        try {
          const response = await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyBdum7iP8PiZQxxFb1OCE7HHx-uZodPI7E`
          );
    
          if (response.ok) {
            const data = await response.json();
    
            result = data.results[0] || "Address not found";

          } else {
            console.error("Reverse geocoding failed.");
          }
        } catch (error) {
          console.error("Error during reverse geocoding:", error);
        }

        return result
    };

    return (
        // <LoadScript
        //     googleMapsApiKey="AIzaSyBdum7iP8PiZQxxFb1OCE7HHx-uZodPI7E"
        //     libraries={libraries}
        // >
        <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={center}
            zoom={findAddress ? 18 : 10}
            onClick={onMapClick}
        >
            <Marker
              position={{
                lat: markerPlaced.lat ? markerPlaced.lat 
                    : findAddress.latitude ? parseFloat(findAddress.latitude)  
                    : typeof findAddress?.geometry?.location?.lat === 'function'
                        ? findAddress.geometry.location.lat()
                        : (typeof findAddress?.geometry?.location?.lat === 'number'
                            ? findAddress.geometry.location.lat
                            : 3.1390), 
                lng: markerPlaced.lng ? markerPlaced.lng 
                    :findAddress.longitude ? parseFloat(findAddress.longitude)   
                    : typeof findAddress?.geometry?.location?.lng === 'function'
                        ? findAddress.geometry.location.lng()
                        : (typeof findAddress?.geometry?.location?.lng === 'number'
                            ? findAddress.geometry.location.lng
                            : 101.6869),
              }}
            />
        </GoogleMap>
        // </LoadScript>
    );
};

export default MapComponent;