//lib
import { Icon } from "@iconify/react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { MDBCol, MDBContainer, MDBRow } from "mdb-react-ui-kit";

//hook
import useWindowDimensions from "../../../../components/hook/use.window.dimensions";

//reducers
import { useDispatch, useSelector } from "react-redux";
import { closeShareEarnModal, openWithdrawalModal, setAnimationModal } from "../../../../redux/reducer/modalReducer";

//components
import Button from "../../../../components/element/button";
import SymbolLine from "../../../../components/common/symbol.line";

export default function ShareEarnInfo() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { width } = useWindowDimensions()
  const { profileInfo, walletBalance } = useSelector((state) => state.order);

  const handleRedirect = (section) => {
    navigate(`/policy?section=${section}`)
  };
  
  const handleCopyCode = (text) => {
    navigator.clipboard.writeText(`${process.env.REACT_APP_URL}?invitation_code=${text}`);
    toast.success("Invitation link with code copied.", {
        autoClose: 1500,
        theme: "light",
        hideProgressBar: true,
        closeOnClick: true,
        closeButton: false,
    });
  }

  const handleOpenWithdrawal = () => {
    if(profileInfo?.bank) {
        dispatch(closeShareEarnModal())
        dispatch(openWithdrawalModal())
        navigate('/order?open=withdrawal')
        dispatch(setAnimationModal(false))
    } else {
        if(profileInfo?.koc_status === 'approved') {
            toast.error("Please complete the KOL details in order to withdraw your profits.", {
                theme: "light",
                hideProgressBar: true,
                closeOnClick: true,
                closeButton: false,
            });
        } else {
            toast.error("Sorry, your KOL application hasn't been approved yet.", {
                theme: "light",
                hideProgressBar: true,
                closeOnClick: true,
                closeButton: false,
            });
        }
    }
  }

  return (
    <MDBRow className="mt-5 text-start">
        <MDBContainer className="share-earn-info-container">
            <div className="flex items-center">
                <MDBCol className="col-7 p-0">
                    <label className="_label --share-earn-label">
                        Referral Discount
                    </label>
                </MDBCol>
                <MDBCol className="col-5 text-end">
                    <label className="_label --share-earn-info">
                        {profileInfo?.koc_status === 'approved' ?
                            profileInfo?.discount_percent > 0 ? profileInfo?.discount_percent : 0
                          : '- '  
                        }
                        {profileInfo?.koc_status ? ' %' : ''} 
                    </label>
                </MDBCol>
            </div>
            <SymbolLine />
            <div className="flex items-center">
                <MDBCol className="col-7 p-0">
                    <label className="_label --share-earn-label">
                        Referral Commission
                    </label>
                </MDBCol>
                <MDBCol className="col-5 text-end">
                    <label className="_label --share-earn-info">
                        {profileInfo?.koc_status === 'approved' ?
                            profileInfo?.bonus_percent > 0 ? profileInfo?.bonus_percent : 0
                          : '- '
                        }
                        {profileInfo?.koc_status === 'approved' ? ' %' : ''} 
                    </label>
                </MDBCol>
            </div>
            <SymbolLine />
            <div className="flex items-center">
                <MDBCol className="col-7 p-0">
                    <label className="_label --share-earn-label">
                        Total Transaction
                    </label>
                </MDBCol>
                <MDBCol className="col-5 text-end">
                    <label className="_label --share-earn-info">
                            {profileInfo?.koc_status === 'approved' ?
                                <span className="bigger">
                                    {profileInfo?.total_transaction > 0 ? profileInfo?.total_transaction : 0}
                                </span> 
                              : '- '
                            }
                    </label>
                </MDBCol>
            </div>
            <SymbolLine />
            <div className="flex items-center">
                <MDBCol className="col-7 p-0">
                    <label className="_label --share-earn-label">
                        Total Purchase Amount
                    </label>
                </MDBCol>
                <MDBCol className="col-5 text-end">
                    <label className="_label --share-earn-info">
                        {profileInfo?.koc_status === 'approved' ? 'RM ' : ''} 
                        {profileInfo?.koc_status === 'approved' ?
                            <span className="bigger">
                                {profileInfo?.total_purchase > 0 ? parseFloat(profileInfo?.total_purchase).toFixed(2) : "0.00"}
                            </span> 
                          : ' -'
                        }
                    </label>
                </MDBCol>
            </div>
            <SymbolLine />
            <div className="flex items-center">
                <MDBCol className="col-7 p-0">
                    <label className="_label --share-earn-label">
                        Total Profit
                    </label>
                </MDBCol>
                <MDBCol className="col-5 text-end">
                    <label className="_label --share-earn-info">
                        {profileInfo?.koc_status === 'approved' ? 'RM ' : ''} 
                        {profileInfo?.koc_status === 'approved' ?
                            <span className="bigger">
                                {profileInfo?.total_profit > 0 ? parseFloat(profileInfo?.total_profit).toFixed(2) : "0.00"}
                            </span> 
                          : ' -'
                        }
                    </label>
                </MDBCol>
            </div>
            <SymbolLine />
            <div className="flex items-center">
                <MDBCol className="col-4 p-0">
                    <label className="_label --share-earn-label">
                        Balance &nbsp;
                    </label>
                </MDBCol>
                <MDBCol className="col-8 text-end flex items-center justify-end">
                    <Button className='withdraw-button me-4'onClick={() => handleOpenWithdrawal()}>Withdraw</Button>
                    <label className="_label --share-earn-info">
                        {profileInfo?.koc_status === 'approved' ? 'RM ' : ''} 
                        {profileInfo?.koc_status === 'approved' ?
                            <span className="bigger">
                                {walletBalance !== null ? parseFloat(walletBalance).toFixed(2) : "0.00"}
                            </span> 
                          : ' -'
                        }
                    </label>
                </MDBCol>
            </div>
            {profileInfo?.invitation_code && 
              <>
                <SymbolLine />
                <div className="mt-5">
                    <label className="_label --share-earn-label">My purchase code</label>
                    <div className="flex justify-between items-center mt-3">
                        <label className="_label --purchase-code">
                            {width > 991 ? 
                                process.env.REACT_APP_ENV === "production" ? 
                                `eople.io?invitation_code=${profileInfo?.invitation_code}` : `etart.com?invitation_code=${profileInfo?.invitation_code}`
                                : width > 550 && width < 991 ? 
                                process.env.REACT_APP_ENV === "production" ? 
                                `mtapp.reople.io?invitation_code=${profileInfo?.invitation_code}` : `memorietart.com?invitation_code=${profileInfo?.invitation_code}`
                                : `vitation_code=${profileInfo?.invitation_code}`
                            }
                        </label>
                        <div 
                            className="copy-circle pointer"
                            onClick={() => handleCopyCode(profileInfo?.invitation_code)}
                        >
                            <Icon 
                                icon="ion:copy-outline" 
                                color={"#F9A01B"} 
                                width={24} 
                                height={24} 
                            />
                        </div>
                    </div>
                </div>
                <div className="text-center _label --share-earn-tc mt-5">
                    By sharing the referral code, you are agree our<br />
                    <span className="pointer" onClick={() => handleRedirect('referral_terms_and_conditions')}>Referral Terms and Conditions.</span>
                </div>
              </>
            }
        </MDBContainer>
    </MDBRow>
  );
}