import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  Blueberry,
  BottleGo,
  ChamKing,
  Christmas,
  Classic,
  ClassicBox,
  CoconutSmooth,
  DoorGift,
  FullDoorGift,
  KitKat,
  MangoSmooth,
  OreoMario,
  PartyBox,
  PremiumTop,
  PureJuice,
  RegularTop,
  SharingBox,
  SharingCaring,
  SingleTart,
  Strawberry,
  Teh,
} from "../../assets/images";
import apiServices from "../../services/api.services";

export const getOutletInfo = createAsyncThunk(
  "getOutletInfo",
  async (payload, thunkAPI) => {
    try {
      const response = await apiServices.getOutletInfo(payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getMenuList = createAsyncThunk(
  "getMenuList",
  async (payload, thunkAPI) => {
    try {
      const response = await apiServices.getMenuList(payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getCategoryList = createAsyncThunk(
  "getCategoryList",
  async (payload, thunkAPI) => {
    try {
      const response = await apiServices.getCategoryList(payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getMenuInfo = createAsyncThunk(
  "getMenuInfo",
  async (payload, thunkAPI) => {
    try {
      const response = await apiServices.getMenuInfo(payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const addCart = createAsyncThunk(
  "addCart",
  async (payload, thunkAPI) => {
    try {
      const response = await apiServices.addCart(payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAnnouncement = createAsyncThunk(
  "getAnnouncement",
  async (payload, thunkAPI) => {
    try {
      const response = await apiServices.getAnnouncement(payload);
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const initialData = {
  categoryList: [
   
    // {
    //     id: '2',
    //     name: 'Classic',
    //     image: Classic,
    // },
    // {
    //     id: '4',
    //     name: 'Premium Top',
    //     image: PremiumTop,
    // },
    // {
    //     id: '5',
    //     name: 'Pure Juice',
    //     image: PureJuice,
    // },
    {
        id: '6',
        name: 'Botol N Go',
        image: Christmas,
    },
    {
      id: '1',
      name: 'Sharing is Caring',
      image: Classic,
    },
    {
      id: '3',
      name: 'Regular Top',
      image: RegularTop,
    },
  ],
  categoryLoading: false,
  menuList: [
    // {
    //     category_id: 1,
    //     category_name: "Sharing is Caring",
    //     id: 1,
    //     name: "3 Pcs “Sharing Box”",
    //     price: 15.90,
    //     image: SharingBox,
    // },
    // {
    //     category_id: 1,
    //     category_name: "Sharing is Caring",
    //     id: 2,
    //     name: "6 Pcs “Door Gift”",
    //     price: 30.90,
    //     image: DoorGift,
    // },
    // {
    //     category_id: 1,
    //     category_name: "Sharing is Caring",
    //     id: 3,
    //     name: "9 Pcs “Party Box”",
    //     price: 79.90,
    //     image: PartyBox,
    // },
    // {
    //     category_id: 2,
    //     category_name: "Classic",
    //     id: 5,
    //     name: "Single Tart",
    //     price: 4.80,
    //     image: SingleTart,
    // },
    // {
    //     category_id: 2,
    //     category_name: "Classic",
    //     id: 6,
    //     name: "3 Pcs “Classic Box”",
    //     price: 15.90,
    //     image: ClassicBox,
    // },
    // {
    //     category_id: 3,
    //     category_name: "Regular",
    //     id: 7,
    //     name: "Oreo Mario",
    //     price: 8.50,
    //     image: OreoMario,
    // },
    // {
    //     category_id: 3,
    //     category_name: "Regular",
    //     id: 8,
    //     name: "Kit Kat Kitty",
    //     price: 8.50,
    //     image: KitKat,
    // },
    // {
    //     category_id: 4,
    //     category_name: "Premium",
    //     id: 9,
    //     name: "Strawberry Saranghae",
    //     price: 12.80,
    //     image: Strawberry,
    // },
    // {
    //     category_id: 4,
    //     category_name: "Premium",
    //     id: 10,
    //     name: "Blueberrylicious",
    //     price: 12.80,
    //     image: Blueberry,
    // },
    // {
    //     category_id: 5,
    //     category_name: "Pure Juice",
    //     id: 11,
    //     name: "Mango Smoothie",
    //     price: 12.40,
    //     image: MangoSmooth,
    // },
    // {
    //     category_id: 5,
    //     category_name: "Pure Juice",
    //     id: 12,
    //     name: "Coconut Smoothie",
    //     price: 12.40,
    //     image: CoconutSmooth,
    // },
    // {
    //     category_id: 6,
    //     category_name: "Botol n Go",
    //     id: 13,
    //     name: "Cham King",
    //     price: 6.90,
    //     image: ChamKing,
    // },
    // {
    //     category_id: 6,
    //     category_name: "Botol n Go",
    //     id: 14,
    //     name: "Hong Kong Teh",
    //     price: 6.90,
    //     image: Teh,
    // },
  ],
  menuInfo: {
    // id: 3,
    // name: "6 Pcs “Door Gift”",
    // image: FullDoorGift,
    // description: "4 Classic Single Tart With 2 Regular",
    // price: 30.80,
    // option_listing: [
    //   {
    //     option_item_max: 1,
    //     option_item_min: 1,
    //     option_id: 1,
    //     option_name: "Classic Tart 1",
    //     option_item: [
    //       {
    //         option_item_id: 1,
    //         option_item_name: "Oreo Mario",
    //         option_item_price: 0
    //       },
    //       {
    //         option_item_id: 2,
    //         option_item_name: "Kit Kat Kitty",
    //         option_item_price: 0
    //       },
    //       {
    //         option_item_id: 3,
    //         option_item_name: "Choco Marsh",
    //         option_item_price: 0
    //       },
    //       {
    //         option_item_id: 4,
    //         option_item_name: "Nutella Pretzel",
    //         option_item_price: 0
    //       }
    //     ]
    //   },
    //   {
    //     option_item_max: 1,
    //     option_item_min: 1,
    //     option_id: 2,
    //     option_name: "Classic Tart 2",
    //     option_item: [
    //       {
    //         option_item_id: 1,
    //         option_item_name: "Oreo Mario",
    //         option_item_price: 0
    //       },
    //       {
    //         option_item_id: 2,
    //         option_item_name: "Kit Kat Kitty",
    //         option_item_price: 0
    //       },
    //       {
    //         option_item_id: 3,
    //         option_item_name: "Choco Marsh",
    //         option_item_price: 0
    //       },
    //       {
    //         option_item_id: 4,
    //         option_item_name: "Nutella Pretzel",
    //         option_item_price: 0
    //       }
    //     ]
    //   }
    // ]
  },
  menuInfoLoading: false,
  cartForm: {
    menu_id: "",
    quantity: 1,
    remark: "",
    method: "",
    option: [],
  },
  outletInfo: {},
  outletInfoLoading: false,
  menuLoading: false,
  menuId: "",
  quantity: 1,
  optionPrice: 0,
  reorderInfo: {},
  announcementList: [],
};

// const sortCategoryList = (categoryList) => {
//   return [...categoryList].sort((a, b) => a.name.localeCompare(b.name));
// };

const dataSlice = createSlice({
  name: "menu",
  initialState: initialData,
  reducers: {
    setMenuId: (state, action) => {
      state.menuId = action.payload;
    },
    setQuantity: (state, action) => {
      state.quantity = action.payload;
    },
    addOptionPrice: (state, action) => {
      state.optionPrice = state.optionPrice + action.payload;
    },
    deleteOptionPrice: (state, action) => {
      state.optionPrice = state.optionPrice - action.payload;
    },
    setTotalOptionPrice: (state, action) => {
      state.optionPrice = action.payload;
    },
    setReorderInfo: (state, action) => {
      state.reorderInfo = action.payload;
    },
  },
  extraReducers: {
    [getOutletInfo.pending]: (state, action) => {
      state.outletInfoLoading = true;
    },
    [getOutletInfo.fulfilled]: (state, action) => {
      state.outletInfoLoading = false;
      state.outletInfo = action.payload.data;
    },
    [getOutletInfo.rejected]: (state, action) => {
      state.outletInfoLoading = false;
    },

    [getMenuList.pending]: (state, action) => {
      state.menuLoading = true;
    },
    [getMenuList.fulfilled]: (state, action) => {
      state.menuLoading = false;
      state.menuList = action.payload.data.menu;

      const categoryList = state.categoryList

      if(categoryList.length > 0) {
        const uniqueCategoryNames = [...new Set(state.menuList.map((item) => item.category_name))];

        categoryList.sort((a, b) => {
          const indexA = uniqueCategoryNames.indexOf(a.name);
          const indexB = uniqueCategoryNames.indexOf(b.name);
          return indexA - indexB;
        });
      }
    },

    [getCategoryList.pending]: (state, action) => {
      state.categoryLoading = true;
    },
    [getCategoryList.fulfilled]: (state, action) => {
      state.categoryLoading = false;
      state.categoryList = action.payload.data;

      const menuList = state.menuList

      if(menuList.length > 0) {
        const uniqueCategoryNames = [...new Set(menuList.map((item) => item.category_name))];

        state.categoryList.sort((a, b) => {
          const indexA = uniqueCategoryNames.indexOf(a.name);
          const indexB = uniqueCategoryNames.indexOf(b.name);
          return indexA - indexB;
        });
      }
    },
    [getCategoryList.rejected]: (state, action) => {
      state.categoryLoading = false;
    },
    [getMenuInfo.pending]: (state, action) => {
      state.menuInfoLoading = true;
    },
    [getMenuInfo.fulfilled]: (state, action) => {
      state.menuInfoLoading = false;
      state.menuInfo = action.payload.data;
    },
    [getMenuInfo.rejected]: (state, action) => {
      state.menuInfoLoading = false;
    },

    [addCart.pending]: (state, action) => {
      state.loading = true;
    },
    [addCart.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [addCart.rejected]: (state, action) => {
      state.loading = false;
    },

    [getAnnouncement.pending]: (state, action) => {
      state.loading = true;
    },
    [getAnnouncement.fulfilled]: (state, action) => {
      state.loading = false;
      state.announcementList = action.payload.data;
    },
    [getAnnouncement.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export const {
  setMenuId,
  setQuantity,
  addOptionPrice,
  deleteOptionPrice,
  setTotalOptionPrice,
  setReorderInfo,
  setSubTotal,
} = dataSlice.actions;

export default dataSlice.reducer;
