import React, { useState, useEffect } from "react";
import { GoogleMap, LoadScript, Autocomplete } from "@react-google-maps/api";
import { useDispatch } from "react-redux";
import {
  setFindAddress,
  setNoAddressFound,
  setTypingAddress,
} from "../../redux/reducer/storeReducer";
import { toast } from "react-toastify";
const libraries = ["places"];

const AddressInput = ({ onPlaceSelected, searchResult, ref }) => {
  const dispatch = useDispatch();
  const [autocomplete, setAutocomplete] = useState(null);

  const onLoad = (autoC) => setAutocomplete(autoC);

  //   const onPlaceChanged = () => {
  //     if (autocomplete !== null) {
  //       onPlaceSelected(autocomplete.getPlace());
  //     }
  //   };

  const checkForAutocompleteResults = () => {
    const dropdownElements = document.getElementsByClassName("pac-item");
    if (dropdownElements.length === 0) {
      dispatch(setNoAddressFound(true));
    } else {
      dispatch(setNoAddressFound(false));
    }
  };

  const onPlaceChanged = () => {
    const place = autocomplete.getPlace();

    const klangValleyBounds = {
      east: 101.8563, 
      west: 101.3624,
      north: 3.4292,
      south: 2.6744,
    };

    if (
      place.geometry &&
      place.geometry.location &&
      place.geometry.location.lat() >= klangValleyBounds.south &&
      place.geometry.location.lat() <= klangValleyBounds.north &&
      place.geometry.location.lng() >= klangValleyBounds.west &&
      place.geometry.location.lng() <= klangValleyBounds.east
    ) {
      if (autocomplete !== null) {
        onPlaceSelected(autocomplete.getPlace());
      }
    } else {
      toast.error("Currently only support Klang Valley.", {
        theme: "light",
        hideProgressBar: true,
        closeOnClick: true,
        closeButton: false,
      });
      dispatch(setTypingAddress(""));
    }
  };

  useEffect(() => {
    dispatch(setFindAddress(""));
  }, {});

  useEffect(() => {
    if (autocomplete) {
    }
  }, [autocomplete]);

  return (
    <LoadScript
      googleMapsApiKey="AIzaSyBdum7iP8PiZQxxFb1OCE7HHx-uZodPI7E"
      libraries={libraries}
    >
      <Autocomplete
        onLoad={onLoad}
        onPlaceChanged={onPlaceChanged}
        options={{
          componentRestrictions: { country: "MY" },
          //   types: ["geocode"], // Restrict to geographic locations
          //   strictBounds: true, // Enforce strict bounds
          //   bounds: {
          //     east: 101.740611, // East longitude limit for Klang Valley
          //     west: 101.586432, // West longitude limit for Klang Valley
          //     north: 3.186287, // North latitude limit for Klang Valley
          //     south: 3.073492, // South latitude limit for Klang Valley
          //   },
        }}
      >
        <input
          ref={ref}
          type="text"
          value={searchResult}
          placeholder="Search New Delivery Address"
          onChange={(e) => {
            checkForAutocompleteResults();
            dispatch(setTypingAddress(e.target.value));
          }}
        />
      </Autocomplete>
    </LoadScript>
  );
};

export default AddressInput;

//AIzaSyBdum7iP8PiZQxxFb1OCE7HHx-uZodPI7E
