//lib

import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useWindowDimensions from "../../components/hook/use.window.dimensions";
import scrollToTop from "../../helpers/scroll.to.top";
import Input from "../../components/element/input";
import {
  closeLoginModal,
  setShowModal,
} from "../../redux/reducer/modalReducer";
import FullModal from "../../components/modal/full.modal.box";
import { MDBCol, MDBContainer, MDBModalBody, MDBRow } from "mdb-react-ui-kit";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import IconButton from "../../components/common/icon.button";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FullLogo } from "../../assets/images";
import Button from "../../components/element/button";
import CodeInput from "../../components/element/code.input";
import authServices from "../../services/auth.services";
import { setToken, setUserId } from "../../redux/reducer/commonReducer";
import { toast } from "react-toastify";
import { Icon } from "@iconify/react";
import { getOrderList } from "../../redux/reducer/orderReducer";
import { useNavigate, useSearchParams } from "react-router-dom";

//assets

//reducers

//components

export default function Login() {
  const modalRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();

  const [searchParams, setSearchParams] = useSearchParams();

  const [top, setTop] = useState();
  const [loginLoading, setLoginLoading] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);

  const [countdown, setCountdown] = useState(null);
  const [timerId, setTimerId] = useState(null);
  const [phone, setPhone] = useState("");
  const [errorStatus, setErrorStatus] = useState("");
  const [firstTimeOtp, setFirstTimeOtp] = useState(true);

  const { loginUsername } = useSelector((state) => state.login);
  const { isOpenLoginModal } = useSelector((state) => state.modal);

  const loginForm = {
    username: loginUsername,
    verification_code: "",
    terms_condition: "",
  };

  const useSchema = Yup.object({
    username: Yup.string()
      .required("Phone number is required")
      .matches(/^(1)[0-9]{8,9}$/, "Enter a valid international phone number (e.g., 123456789)"),
    verification_code: Yup.string()
      .min(6, "OTP must be at least 6 characters")
      .required("OTP is required"),
    terms_condition: Yup.bool().required().oneOf([true], ""),
  });

  const backButton = () => {
    dispatch(closeLoginModal());
    setSearchParams('');
  };

  const handleRedirect = (section) => {
    navigate(`/policy?section=${section}`)
  };

  const handleSubmit = async ({ values, setFieldError }) => {
    setLoginLoading(true);

    try {
      const response = await authServices.login({
        username: `60${values.username}`,
        verification_code: values.verification_code,
        type: "phone verification",
      });

      if (response) {
        dispatch(setShowModal(false));
        setTimeout(() => {
          // dispatch(setAnimationModal(true))
          dispatch(closeLoginModal());
          setLoginLoading(false);

          localStorage.setItem("username", `60${values.username}`);
          localStorage.setItem("userId", response.data.id);
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("login", 'full');
          //   localStorage.setItem("invitationCode", response.data.invitation_code);

          dispatch(setToken(response.data.token));
          dispatch(getOrderList());

          toast.success("Login successful.", {
            autoClose: 1500,
            theme: "light",
            hideProgressBar: true,
            closeOnClick: true,
            closeButton: false,
          });
        }, 500);
      }
    } catch (ex) {
      setLoginLoading(false);
      // validateForm();
      if (ex && ex.response.status === 422) {
        const errors = ex.response.data.errors;
        if (errors && Object.keys(errors).length > 0) {
          Object.keys(errors).map((item, i) =>
            setFieldError(item, errors[item])
          );
        }
      }
    }
  };

  useEffect(() => {
    if (countdown === 0) {
      clearInterval(timerId);
      setCountdown(null);
    }
  }, [countdown, timerId]);

  useEffect(() => {
    if (countdown > 0 && errorStatus === "false") {
    } else if (countdown === 0) {
      setErrorStatus("");
      // validateForm()
    }
  }, [countdown]);

  const handleSendOtp = async ({ values, setFieldError, setFieldValue }) => {
    setOtpLoading(true);

    if (firstTimeOtp) {
      setFirstTimeOtp(false);
    }

    try {
      const response = await authServices.sendVerificationCode({
        username: `60${values.username}`,
        type: "phone verification",
      });

      if (response) {
        setOtpLoading(false);
        setFieldValue(values, values);
        if (countdown === null) {
          setCountdown(120);
          const timer = setInterval(() => {
            setCountdown((prevCountdown) => prevCountdown - 1);
          }, 1000);
          setTimerId(timer);
        }
      }
    } catch (ex) {
      setOtpLoading(false);
      setErrorStatus("false");

      if (ex && ex.response.status === 422) {
        const errors = ex.response.data.errors;
        if (errors && Object.keys(errors).length > 0) {
          Object.keys(errors).map((item, i) => {
            const errorMesage = errors[item];
            const regex = /\d+/;
            const matches = errorMesage[0].match(regex);
            if (matches) {
              const number = parseInt(matches[0], 10);
              if (countdown === null) {
                setCountdown(number);
                const timer = setInterval(() => {
                  setCountdown((prevCountdown) => prevCountdown - 1);
                }, 1000);
                setTimerId(timer);
                setFieldError(item, errors[item]);
              }
            } else {
            }
          });
        }
      }
    }
  };

  const formatTimer = (time) => {
    if (time === 120) {
      return `( ${time}s )`;
    } else {
      return `( ${time}s )`;
    }
  };

  useEffect(() => {
    setTop(true);
  }, [isOpenLoginModal]);

  useEffect(() => {
    if (top == true && modalRef.current) {
      scrollToTop(modalRef.current);
      setTop(false);
    }
  }, [top]);

  return (
    <FullModal
      centered
      scrollable
      staticBackdrop={true}
      show={isOpenLoginModal}
      backButton={backButton}
      screenSize={"xl"}
      dialogClassName="--margin"
      contentClassName="--short"
      content={
        <>
          <MDBModalBody ref={modalRef}>
            <Formik
              initialValues={loginForm}
              validationSchema={useSchema}
              validateOnChange={true}
              enableReinitialize={true}
              onSubmit={(values, { errors, setFieldError }) => {
                handleSubmit({ values, errors, setFieldError });
              }}
            >
              {({
                setFieldValue,
                isSubmitting,
                handleReset,
                isValid,
                errors,
                values,
                handleSubmit,
                setFieldError,
              }) => (
                <Form>
                  <MDBContainer className="login-container">
                    <div className="flex justify-between items-baseline mb-5 mt-5">
                      <div />
                      <div 
                        className="image-frame --logo"
                        style={{ marginLeft: '4em' }}
                      >
                        <LazyLoadImage
                            src={FullLogo} // || picture
                            alt="..."
                        />
                      </div>
                      <div>
                        <IconButton
                          icon="akar-icons:cross"
                          width="18"
                          height="20"
                          onClick={() => backButton()}
                        />
                      </div>
                    </div>
                    <MDBContainer className="input-container">
                      <div>
                        <Input
                          type="number"
                          name="username"
                          label="Phone Number"
                          placeholder="123456789"
                          as="line-field"
                          inputClassName="phone_no"
                          // className="phone-input"
                          // autoFocus={usernameInputRef}
                          // inputMode="numeric"
                        />
                        <CodeInput
                          // className="form-input"
                          name="verification_code"
                          label="Enter OTP"
                          disabled={false}
                        />
                      </div>
                      <MDBRow
                        className="mt-5 pointer"
                        onClick={() => {
                          setFieldValue(
                            "terms_condition",
                            !values.terms_condition
                          );
                        }}
                      >
                        <MDBCol className="col-lg-1 col-md-1 col-1">
                          <input
                            type="checkbox"
                            name="terms_condition"
                            className="pointer"
                            checked={values.terms_condition}
                            onChange={(e) =>
                              e.target.checked
                                ? setFieldValue("terms_condition", true)
                                : setFieldValue("terms_condition", false)
                            }
                          />
                        </MDBCol>
                        <MDBCol className="col-lg-11 col-md-11 col-11">
                          <label className="_label --login-tc">
                            I have read and agree to memorie{" "}
                            <span className="pointer" onClick={() => handleRedirect('terms_and_conditions')}>
                              Terms and Conditions
                            </span>{" "}
                            and <span className="pointer" onClick={() => handleRedirect('privacy_policy')}>Privacy Policy</span>
                          </label>
                        </MDBCol>
                      </MDBRow>
                    </MDBContainer>
                    <div
                      className="_label --request-otp pointer"
                      onClick={() =>
                        handleSendOtp({ values, setFieldError, setFieldValue })
                      }
                    >
                      {otpLoading ? (
                        <Icon
                          icon="line-md:loading-twotone-loop"
                          width={25}
                          height={25}
                        />
                      ) : countdown === null && firstTimeOtp ? (
                        "Request OTP"
                      ) : countdown === null && !firstTimeOtp ? (
                        "Resend OTP"
                      ) : (
                        `Resend ${formatTimer(countdown)}`
                      )}
                    </div>
                    <div className={width > 550 ? "ps-5 pe-5" : ""}>
                      <MDBRow className="mt-1 button-container --notFixed w-auto">
                        <Button
                          type="submit"
                          disabled={isValid && values.verification_code?.length === 6 ? false : true}
                          className="w-100 pointer"
                        >
                          LOG IN / SIGN UP
                        </Button>
                      </MDBRow>
                    </div>
                  </MDBContainer>
                </Form>
              )}
            </Formik>
          </MDBModalBody>
        </>
      }
    />
  );
}
