import React, { useEffect, useState } from 'react';
import { Dropdown } from 'primereact/dropdown'
import { useSearchParams} from 'react-router-dom';
import useWindowDimensions from '../../../components/hook/use.window.dimensions';

export default function PolicyDropdown({ section1, section2, section3 }) {
  const { width } = useWindowDimensions()
  const [searchParams, setSearchParams] = useSearchParams();

  const activeSection = searchParams.get("section")

  const [isFixed, setIsFixed] = useState(false);
  const [isScroll, setIsScroll] = useState(false);
  const [selectedSection, setSelectedSection] = useState('');
  const [activeMenu, setActiveMenu] = useState(
    activeSection === 'terms_and_conditions' ? 'Terms and Conditions' :
    activeSection === 'privacy_policy' ? 'Privacy Policy' :
    activeSection === 'referral_terms_and_conditions' ? '"Referral Terms and Conditions' :
    'Terms and Conditions'
  );

  const policyMenus = [
    "Terms and Conditions", 
    "Privacy Policy", 
    "Referral Terms and Conditions"
  ]

  const handleSelectMenu = (e) => {
    setIsScroll(true)
    setSelectedSection(e.target.value)
  }

  const scrollToSection = (sectionRef) => {
    const navbarHeight = 200; // Replace with the actual height of your navbar
    if (sectionRef.current) {
      const position = sectionRef.current.offsetTop - navbarHeight;
      window.scrollTo({
        top: position,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    const scrollToSectionWithDelay = (sectionRef) => {
      setTimeout(() => {
        scrollToSection(sectionRef);
      }, 100); // 100 milliseconds delay
    };

    switch (activeSection) {
      case 'terms_and_conditions':
        scrollToSectionWithDelay(section1);
        break;
      case 'privacy_policy':
        scrollToSectionWithDelay(section2);
        break;
      case 'referral_terms_and_conditions':
        scrollToSectionWithDelay(section3);
        break;
      default:
        break;
    }
  }, [activeSection]);
  
  useEffect(() => {
    function handleScroll() {
      setIsScroll(false)
      const section1Top = section1.current.offsetTop;
      const section2Top = section2.current.offsetTop;
      const section3Top = section3.current.offsetTop;
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;

      const fixedPosition = 100;
      setIsFixed(scrollPosition > fixedPosition);
      
      if (scrollPosition >= section1Top - windowHeight * 0.15 && scrollPosition < section2Top - windowHeight * 0.15) {
        setActiveMenu("Terms and Conditions")
      } else if (scrollPosition >= section2Top - windowHeight * 0.15 && scrollPosition < section3Top - windowHeight * 0.15) {
        setActiveMenu("Privacy Policy")
      } else if (scrollPosition >= section3Top - windowHeight * 0.15) {
        setActiveMenu("Referral Terms and Conditions")
      }
    }
    
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [section1, section2 , section3]);

  useEffect(() => {
    const navbarHeight = 100;

    const scrollToSection = (sectionRef) => {
        if (sectionRef.current) {
            const sectionTop = sectionRef.current.getBoundingClientRect().top + window.pageYOffset;
            const offsetPosition = sectionTop - navbarHeight;

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });
        }
    };

    if (selectedSection === "Terms and Conditions") {
        scrollToSection(section1);
    } else if (selectedSection === "Privacy Policy") {
        scrollToSection(section2);
    } else if (selectedSection === "Referral Terms and Conditions") {
        scrollToSection(section3);
    }

  }, [selectedSection]);

  return (
    <div className={`policy-dropdown ${isFixed ? '--fixed' : ''} `} style={{display : width < 991 ? "" : "none"}}>
      <Dropdown 
        value={activeMenu} 
        onChange={(e) => handleSelectMenu(e)} 
        options={policyMenus} 
        className="w-full md:w-14rem" 
      />
    </div>
  );
}
