//lib

import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useWindowDimensions from "../../../components/hook/use.window.dimensions";
import scrollToTop from "../../../helpers/scroll.to.top";
import HalfModal from "../../../components/modal/half.modal.box";
import { MDBCol, MDBContainer, MDBModalBody, MDBRow } from "mdb-react-ui-kit";
import Button from "../../../components/element/button";
import { closeReOrderModal, openCartModal } from "../../../redux/reducer/modalReducer";
import { useNavigate } from "react-router-dom";
import { getCartInfo, updateCart } from "../../../redux/reducer/checkoutReducer";
import { getMenuInfo, setMenuId } from "../../../redux/reducer/menuReducer";

//assets

//reducers

//components

export default function ReOrder({ banks }) {
    const modalRef = useRef(null)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { width } = useWindowDimensions();
    
    const { reorderInfo } = useSelector((state) => state.menu);
    const { deliveryAddressId } = useSelector((state) => state.common);
    const { addressList } = useSelector((state) => state.store);
    const { isOpenReOrderModal } = useSelector((state) => state.modal);

    const [ top, setTop ] = useState();

    const shipping = localStorage.getItem('shipping')

    const backButton = () => {
        dispatch(closeReOrderModal())
    }

    const handleMakeAnother = (id) => {
        dispatch(setMenuId(id));
        dispatch(closeReOrderModal())
        navigate(`/menu?open=add_cart&id=${id}`)
    }

    useEffect(() => {
      setTop(true);
    }, [isOpenReOrderModal]);
        
    useEffect(() => {
      if (top == true && modalRef.current) {
        scrollToTop(modalRef.current);
        setTop(false);
      }
    }, [top]);

    return (
        <>
            <HalfModal
                centered
                scrollable
                staticBackdrop={true}
                show={isOpenReOrderModal}
                backButton={backButton}
                desktopModal={width >= 991 ? true : false}
                screenSize={width >= 991 ? "xl" : ""}
                className="reorder-modal"
                content={
                    <>
                        <MDBModalBody ref={modalRef}>
                            <MDBContainer className="reorder-container mt-4"> 
                                <MDBRow className="reorder-header">
                                    <MDBCol className="col-8">
                                        <label className="_label --reorder-label">{reorderInfo.name}</label>
                                    </MDBCol>
                                    <MDBCol className="text-end">
                                        <div className="_label --reorder-label">{parseFloat(reorderInfo.price).toFixed(2)}</div>
                                        <div className="_label --total-price">Total price</div>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow className="mt-5">
                                    <div className="flex justify-between items-center _label --reorder-info gap-5">
                                        <div className="w-100">
                                            <label>{reorderInfo.name}</label>
                                            <br/>
                                            {reorderInfo.options?.length > 0 && reorderInfo.options.map((option, optionIndex) => (
                                                <div className="_label --reorder-addon">{option.name}</div>
                                            ))}
                                        </div>
                                        <div className="quantity-circle">{reorderInfo.quantity}</div>
                                        <div>{parseFloat(reorderInfo.price).toFixed(2)}</div>
                                    </div>
                                </MDBRow>
                                <MDBRow className="flex justify-center">
                                    <div className="mt-1 button-container --full"> 
                                        <Button className="w-100" onClick={() => handleMakeAnother(reorderInfo.id)}>Make Another</Button>
                                    </div>
                                </MDBRow>
                            </MDBContainer>
                        </MDBModalBody>
                    </>
                }
            />
        </>
    );
}