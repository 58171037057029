//lib
import { Badge } from "primereact/badge";
import { useLocation, useNavigate } from "react-router-dom";

//assets

//reducers
import { useSelector, useDispatch } from "react-redux";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import { Icon } from "@iconify/react";
import CategoryBar from "../../pages/Menu/components/category.bar";
import { getCategoryList } from "../../redux/reducer/menuReducer";

//components

export default function TopNavbar({ withCategory }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { navigation_item } = useSelector((state) => state.common);
  const { cartCounts } = useSelector((state) => state.checkout);

  return (
    <header
      className={`fixed-top top-navbar ${
        withCategory && pathname === "/menu" ? "--withCategory" : ""
      }`}
    >
      <MDBRow>
        {navigation_item.map((item, itemIndex) => {
          return (
            <MDBCol
              key={itemIndex}
              className="col-4 pointer"
              onClick={() => {
                navigate(item.path);
              }}
            >
              <div>
                <Icon
                  icon={pathname !== item.path ? item.icon : item.active_icon}
                  color={pathname == item.path ? "#113B63" : "#9ebcd8"}
                  width={item.path === '/cart' ? "32" : "28"}
                  height={item.path === '/cart' ? "32" : "28"}
                />
                {item.count && cartCounts > 0 && (
                  <Badge value={cartCounts} className="badge" />
                )}
              </div>
            </MDBCol>
          );
        })}
      </MDBRow>
      {withCategory && pathname === "/menu" && (
        <CategoryBar withCategory={withCategory} />
      )}
    </header>
  );
}
