//lib
import { Icon } from "@iconify/react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Skeleton } from "primereact/skeleton";
import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import { LazyLoadImage } from "react-lazy-load-image-component";

//assets

//hook
import useWindowDimensions from "../../../components/hook/use.window.dimensions";

//reducer
import { useDispatch, useSelector } from "react-redux";
import {
  getGuestToken,
  setOutletLoading,
} from "../../../redux/reducer/storeReducer";
import { clearCart } from "../../../redux/reducer/checkoutReducer";
import { setCurrentLocation } from "../../../redux/reducer/commonReducer";

//helper
import { getLocation } from "./../../../helpers/locationHelper";
import { LionDanceOne, LionDanceTwo } from "../../../assets/images";

export default function OutletListing({
  outlets,
  loading,
  addresses,
  option,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();

  const { currentLocation } = useSelector(
    (state) => state.common
  );

  const token = localStorage.getItem("token");
  const cartId = localStorage.getItem("cartId");
  const outletId = localStorage.getItem("outletId");
  const deliveryAddressId = localStorage.getItem("deliveryAddressId");

  const handleChooseOutlet = (id) => {
    if (outletId && outletId !== undefined && id !== parseInt(outletId)) {
      handleClearCart();
    }

    if (option === "Delivery") {
      if (addresses.length > 0) {
        if (deliveryAddressId) {
          navigate("/menu");
          localStorage.setItem("outletId", id);
        } else {
          toast.error("Please set delivery address.", {
            theme: "light",
            hideProgressBar: true,
            closeOnClick: true,
            closeButton: false,
          });
          navigate(`/?open=set_address`)
        }
      } else {
        toast.error("Please set delivery address.", {
          theme: "light",
          hideProgressBar: true,
          closeOnClick: true,
          closeButton: false,
        });
        navigate(`/?open=set_address`)
      }
    } else {
      navigate("/menu");
      localStorage.setItem("outletId", id);
    }
  }

  const handleRedirect = (id) => {
    if(token) {
      handleChooseOutlet(id)
    } else {
      setTimeout(() => {
        dispatch(getGuestToken())
        .unwrap()
        .then((res) => {
          handleChooseOutlet(id)
        })
      }, 500);
    }
  };

  const handleClearCart = () => {
    if (cartId && cartId !== undefined) {
      dispatch(
        clearCart({
          id: cartId,
        })
      )
        .unwrap()
        .then((res) => {})
        .catch((ex) => {
          if (ex && ex.response.status === 404) {
            localStorage.removeItem("cartId");
          }
        });
    }
  };

  const handleAskLocation = () => {
    dispatch(setOutletLoading(true));
    
    setTimeout(() => {
      getLocation((location) => {
        dispatch(setCurrentLocation(location));
        dispatch(setOutletLoading(false));
      });
    }, 300);
  };
  
  return (
    <MDBRow className="flex justify-center mt-4">
      <MDBRow className="p-4 pt-0 pb-0 flex justify-center">
        <div className="select-outlet flex justify-center items-center">
          Select Outlet to <span>&nbsp;Made&nbsp;</span> Order
          <Icon
            icon="ant-design:aim-outlined"
            onClick={() => handleAskLocation()}
            width={22}
            height={22}
            className="ms-3 pointer"
          />
        </div>
      </MDBRow>
      <MDBRow 
        className={`outlet-listing ${ width > 550 ? 'mt-5' : 'mt-0'}`} 
      >
        {!loading
          ? outlets?.length > 0 &&
            outlets.map(
              (outlet, outletIndex) =>
                outlet.address && (
                  <MDBCol
                    key={outletIndex}
                    className={`flex justify-center col-lg-6 col-md-12 col-12 mt-5 pointer relative`}
                    onClick={() => {
                      if (outlet.remark !== "closed" && !loading) {
                        handleRedirect(outlet.id);
                      }
                    }}
                  >
                    <div
                      className={`outlet-tab ${
                        outlet.remark === "closed" ? "--closed" : ""
                      } flex items-center gap-0`}
                    >
                      {outlet.remark === "closed" && (
                        <label className="closed-text pointer">
                          Closed Now
                        </label>
                      )}
                      <MDBCol className="col-lg-4 col-md-4 col-5 p-1">
                        <div className="image-frame --store">
                          <LazyLoadImage
                            src={outlet.logo_attachment}
                            alt="..."
                          />
                        </div>
                      </MDBCol>
                      <MDBCol className="col-lg-8 col-md-8 col-7 p-1">
                        <label className="_label --outlet pointer">
                          {outlet.display_name ?? ""}
                        </label>
                        <div className="outlet-line">
                          <label className="_label --distance pointer">
                            {(currentLocation.lat && outlet.distance) ||
                              "No distance shown"}
                          </label>
                        </div>
                        <label className="_label --address mt-3 pointer">
                          Address
                          <br />
                          <span>{outlet.address}</span>
                        </label>
                      </MDBCol>
                    </div>
                    {/* new year */}
                    {/* {width > 600 && <img src={LionDanceTwo} className={`lion-dance`}/>} */}
                  </MDBCol>
                )
            )
          : Array.from({ length: 4 }, (_, storeDetailsIndex) => (
              <MDBCol
                className={`flex justify-center col-lg-6 col-md-6 col-12 mt-5 `}
              >
                <div 
                  className="outlet-tab flex gap-3 w-100 p-3 justify-center items-center" 
                  style={{ height: !loading ? width > 991 ? '162px' : '140px' : '',
                    background: '#305676'
                  }}
                >
                  <div className="p-2">
                    <Skeleton width="140px" height="120px" borderRadius="10px" />
                  </div>
                  <div className="w-100">
                    <Skeleton width={width > 991 ? "70%" : "80%"} height={width > 991 ? "1.5em" : "1.2em"} className="mt-0" />
                    <Skeleton
                      width={width > 991 ? "40%" : "60%"}
                      height="1.2em"
                      className="mt-3 mb-5"
                    />
                    {Array.from({ length: 2 }, (_, storeDetailsIndex) => (
                      <Skeleton width={width > 991 ? "90%" : "100%"} height="1.1em" className="mt-3" />
                    ))}
                  </div>
                </div>
              </MDBCol>
            ))}
      </MDBRow>
    </MDBRow>
  );
}
