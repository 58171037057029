import { useEffect, useRef, useState } from "react";

//lib
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { MDBContainer, MDBModalBody, MDBRow } from "mdb-react-ui-kit";

//helper
import scrollToTop from "../../../helpers/scroll.to.top";

//hook
import useWindowDimensions from "../../../components/hook/use.window.dimensions";

//reducers
import { useDispatch, useSelector } from "react-redux";
import { getProfile } from "../../../redux/reducer/orderReducer";
import { closeEditKocModal, openShareEarnModal, setAnimationModal } from "../../../redux/reducer/modalReducer";

//components
import Input from "../../../components/element/input";
import Button from "../../../components/element/button";
import FullModal from "../../../components/modal/full.modal.box";
import ModalHeader from "../../../components/header/modal.header";

//service
import apiServices from "../../../services/api.services";

export default function EditKOCDetails() {
    const modalRef = useRef(null);
    const dropdownRef = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { width } = useWindowDimensions();

    const [ top, setTop ] = useState();
    const [ dropGender, setDropGender ] = useState(false);

    const { genderOptions, profileInfo } = useSelector((state) => state.order);
    const { isOpenEditKocModal } = useSelector((state) => state.modal);

    const username = localStorage.getItem('username')

    const formatDate = (value, format) => {
        if (format === 'yyyy-mm-dd') {
          const [day, month, year] = value.trim().split('/');
          return `${year}-${month}-${day}`;
        } else if (format === 'dd/mm/yyyy') {
          const [year, month, day] = value.trim().split('-');
          return `${day}/${month}/${year}`;
        } else {
          const [month, day, year] = value.trim().split('/');
          return `${day}/${month}/${year}`;
        }
    };      
    
    const returnFormattedBirthday = profileInfo.dob ? formatDate(profileInfo.dob, 'yyyy-mm-dd') : '';

    const kocForm = {
        name: profileInfo.name ?? '',
        phone_no: profileInfo.phone_number ? profileInfo.phone_number : username ? username : '',
        birthday: returnFormattedBirthday ? returnFormattedBirthday : '',
        gender: profileInfo.gender ?? '',
        city: profileInfo.city ?? '',
        bank: profileInfo.bank ?? '',
        bank_account: profileInfo.bank_account_no ?? '',
    }

    const useSchema = Yup.object({
        name: Yup.string().required("Name is required"),
        phone_no: Yup.string().required("Phone number is required"),
        birthday: Yup.string().required("Birthday month is required"),
        gender: Yup.string().required("Gender is required"),
        city: Yup.string().required("City is required"),
        bank: Yup.string().required("Bank is required"),
        bank_account: Yup.string().required("Bank account is required"),
    });


    const backButton = () => {
        dispatch(closeEditKocModal())
        dispatch(openShareEarnModal())
        navigate('/order?open=share_earn')
        dispatch(setAnimationModal(false))
    }

    const backToOrder = () => {
        dispatch(closeEditKocModal())
        navigate('/order')
    }

    const handleSubmit = async ({ values, setFieldError }) => { 
        const formattedBirthday = formatDate(values.birthday, 'dd/mm/yyyy');

        try {
            const response = await apiServices.updateProfile({
                name: values.name,
                phone_no: values.phone_no,
                date_of_birth: formattedBirthday,
                gender: values.gender,
                city: values.city,
                bank: values.bank,
                bank_account_no: values.bank_account,
            });

            if (response) {
                dispatch(getProfile())
                dispatch(closeEditKocModal())
                dispatch(openShareEarnModal())
                navigate('/order?open=share_earn')
            }

        } catch (ex) {
            if (ex && ex.response.status === 422) {
                const errors = ex.response.data.errors;
                if (errors && Object.keys(errors).length > 0) {
                    Object.keys(errors).map((item, i) => {
                        setFieldError(item, errors[item]);
                    });
                }
            }
        };
    }

    const handleChooseGender = (value, { setFieldValue }) => {
        setFieldValue('gender', value)
        setDropGender(false)
    }

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropGender(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [dropGender]);

    useEffect(() => {
        setTop(true);
    }, [isOpenEditKocModal]);
      
    useEffect(() => {
        if (top == true && modalRef.current) {
            scrollToTop(modalRef.current);
            setTop(false);
        }
    }, [top]);

    return (
        <FullModal
            centered
            scrollable
            staticBackdrop={true}
            show={isOpenEditKocModal}
            backButton={backToOrder}
            screenSize={width >= 991 ? "xl" : "fullscreen-xl-down"}
            contentClassName=""
            content={
                <>
                    <ModalHeader title="Edit KOL Details" backButton={backButton} backButtonNoAnimation={backButton} />
                    <MDBModalBody ref={modalRef}>
                        <Formik
                            initialValues={kocForm}
                            validationSchema={useSchema}
                            enableReinitialize={true}
                            onSubmit={(values, { errors, setFieldError }) => {
                                handleSubmit({ values, errors, setFieldError });
                            }}
                        >
                            {({
                                setFieldValue,
                                errors,
                            }) => (
                                <Form>
                                    <MDBContainer className="edit-koc-container"> 
                                        <div className="_label --edit-koc-desc text-center">
                                            Some info only allowed limited change attempt. 
                                            <br />Please make sure to fill in the correct information.
                                        </div>
                                        <div className="p-2 mt-5">
                                            <Input
                                                name="name"
                                                placeholder="Full Name"
                                                isRequired={true}
                                            />          
                                            <Input
                                                name="phone_no"
                                                placeholder="Phone No"
                                                disabled={true}
                                                isRequired={true}
                                                inputClassName="--autofill"
                                            />
                                            <Input
                                                name="birthday"
                                                placeholder="Birthday Month"
                                                type="date"
                                                isRequired={true}
                                                values={profileInfo.dob ? returnFormattedBirthday : ''}
                                            />
                                            <div className="relative">
                                                <Input
                                                    name="gender"
                                                    placeholder="Gender"
                                                    isRequired={true}
                                                    onClick={() => setDropGender(!dropGender)}
                                                    readOnly
                                                />
                                                <div 
                                                    ref={dropdownRef}
                                                    className={`gender-container ${!dropGender ? 'hide' : ''}`}
                                                    style={{ 
                                                        marginTop: errors && errors['gender'] ? '2.5em' : '5em'
                                                    }}
                                                >
                                                    {genderOptions?.map((option, optionIndex) => (
                                                        <MDBRow 
                                                            className="row pointer"
                                                            onClick={() => handleChooseGender(option.value, {setFieldValue})}
                                                        >
                                                            <label className="pointer">{option.label}</label>
                                                        </MDBRow>
                                                    ))}
                                                </div>
                                            </div>
                                            <Input
                                                name="city"
                                                placeholder="City You Stay"
                                                isRequired={true}
                                            />
                                            <Input
                                                name="bank"
                                                placeholder="Bank"
                                                isRequired={true}
                                            />
                                            <Input
                                                name="bank_account"
                                                placeholder="Bank Account"
                                                isRequired={true}
                                            />
                                        </div>
                                    </MDBContainer>
                                    <div className="mt-1 button-container"> 
                                        <Button type="submit" className="w-100">Complete & Start</Button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </MDBModalBody>
                </>
            }
        />
    );
}