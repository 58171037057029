import { useEffect, useRef, useState } from "react";

//lib
import Lottie from "lottie-react";
import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { MDBContainer, MDBModalBody, MDBRow } from "mdb-react-ui-kit";

//helper
import scrollToTop from "../../../helpers/scroll.to.top";

//hook
import useWindowDimensions from "../../../components/hook/use.window.dimensions";

//reducers
import { useDispatch, useSelector } from "react-redux";
import { closeShareEarnModal, openEditKocModal, openLoginModal, setAnimationModal } from "../../../redux/reducer/modalReducer";

//components
import PayoutHistory from "./components/payout.history";
import ShareEarnInfo from "./components/share.earn.info";
import FullModal from "../../../components/modal/full.modal.box";
import ModalHeader from "../../../components/header/modal.header";
import { DotsLoading } from "../../../assets/lotties";

export default function ShareEarnProgram() {
    const modalRef = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { width } = useWindowDimensions();

    const [ top, setTop ] = useState();
    const { isOpenShareEarnModal } = useSelector((state) => state.modal);
    const { profileInfo, profileInfoLoading } = useSelector((state) => state.order);

    const backButton = () => {
        dispatch(closeShareEarnModal())
        navigate('/order')
    }

    const handleRedirect = () => {
        dispatch(closeShareEarnModal())
        dispatch(openEditKocModal())
        navigate('/order?open=edit_kol')
        dispatch(setAnimationModal(false))
    }

    useEffect(() => {
        setTop(true);
        dispatch(setAnimationModal(true))
    }, [isOpenShareEarnModal]);
      
    useEffect(() => {
        if (top == true && modalRef.current) {
            scrollToTop(modalRef.current);
            setTop(false);
        }
    }, [top]);

    return (
        <FullModal
            centered
            scrollable
            staticBackdrop={true}
            show={isOpenShareEarnModal}
            backButton={backButton}
            screenSize={width >= 991 ? "xl" : "fullscreen-xl-down"}
            contentClassName="--white"
            content={
                <>
                    <ModalHeader title="Share & Earn Program" backButton={backButton} />
                    <MDBModalBody ref={modalRef}>
                      {!profileInfoLoading ?
                        <MDBContainer className="share-earn-container"> 
                            <label className="_label --share-earn-desc">
                                Share your unique 'Memories Tart' purchase code with friends and family. You'll earn rewards and they'll get discounts on their purchases.
                            </label>
                            <ShareEarnInfo />
                            {profileInfo.koc_status !== null && profileInfo.koc_status === 'approved' &&
                                <>
                                    <MDBRow className="p-5" >
                                        <div 
                                            className="flex items-center justify-center complete-koc gap-2"
                                            onClick={() => handleRedirect()}
                                        > 
                                            <Icon
                                                icon="iconamoon:edit-light" 
                                                color={"#878585"} 
                                                width={18} 
                                                height={18} 
                                            />
                                            {profileInfo.name !== null ? "My" : "Complete"} KOL Details
                                        </div>
                                    </MDBRow>
                                    <label className="_label --share-earn-desc">
                                        Our payment terms are as follows: Once you complete our KOL, we will automatically process your commission on the 1st & 15th of each month.
                                    </label>
                                </>
                            }
                        </MDBContainer>
                      : 
                        <div className="lottie-loading flex justify-center" style={{ paddingTop: '18em' }}>
                            <div>
                                <div className="lottie-frame --receipt flex justify-content-center w-100">
                                    <div className="image-frame">
                                        <Lottie animationData={DotsLoading} />
                                    </div>
                                </div>
                            </div>
                        </div>
                      }
                    </MDBModalBody>
                </>
            }
        />
    );
}