import { useLocation } from "react-router-dom";
import Footer from "../footer/footer";
import MainHeader from "../header/main.header";
import useWindowDimensions from "../hook/use.window.dimensions";
import BottomNavBar from "../navbar/bottom.navbar";
import SideNavbar from "../navbar/side.navbar";
import TopNavbar from "../navbar/top.navbar";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import scrollToTop from "../../helpers/scroll.to.top";
import FullPageLoading from "../common/full.page.loading";
import SnowEffect from "../common/snow.effect";
import FireworkEffect from "../common/firework.effect";

export default function MainLayout(props) {
  const { pathname } = useLocation();
  const { width } = useWindowDimensions();

  const [ withCategory, setWithCategory ] = useState(false)
  const { fullPageLoading } = useSelector((state) => state.common);

  useEffect(() => {
    if (fullPageLoading || pathname === '/') {
      if(fullPageLoading) {
        document.body.style.overflow = 'hidden'
      } else if (pathname === '/') {
        document.body.style.overflowX = 'hidden'
      }
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [fullPageLoading, pathname]);

  const stickCategoryToTop = () => {
    const scrolled = document.documentElement.scrollTop || document.body.scrollTop;
    if (scrolled > 200) {
      setWithCategory(true);
    } else if (scrolled < 200) {
      setWithCategory(false);
    }
  };

  document.addEventListener("scroll", stickCategoryToTop);

  return (
    <div className="relative hidden body-container">
      {/* {pathname === '/' && <FireworkEffect />} */}
      {fullPageLoading && 
        <FullPageLoading />
      }
      {pathname !== '/' && <TopNavbar withCategory={withCategory} />}
        <div className={`content-container`} style={{ height: pathname === '/order' ? 'unset' : '100%', minHeight: pathname === '/order' ? 'unset' : '100vh'}}>
          {props.children}
        </div>
      {pathname === '/' && <Footer />}
    </div>
  );
}
