//lib

import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useWindowDimensions from "../hook/use.window.dimensions";
import { closeChangeCartModal } from "../../redux/reducer/modalReducer";
import { clearCart, getCartCount, getCartInfo, setCarts, updateCart } from "../../redux/reducer/checkoutReducer";
import scrollToTop from "../../helpers/scroll.to.top";
import FullModal from "../modal/full.modal.box";
import { MDBContainer, MDBModalBody, MDBRow } from "mdb-react-ui-kit";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Change } from "../../assets/images";
import Button from "../element/button";
import { useNavigate } from "react-router-dom";
import { clearOrderList, getWalletBalance, getWalletHistory, setCallWalletHistory } from "../../redux/reducer/orderReducer";
import { getAddressList } from "../../redux/reducer/storeReducer";
import { setConfirmationItem } from "../../redux/reducer/commonReducer";
import { toast } from "react-toastify";
import { Icon } from "@iconify/react";
import apiServices from "../../services/api.services";

//assets

//reducers

//components

export default function ConfirmationModal({ cart }) {
  const modalRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();

  const [top, setTop] = useState();
  const [confirmationLoading, setConfirmationLoading] = useState(false);

  const { addressList } = useSelector((state) => state.store);
  const { confirmationItem } = useSelector((state) => state.common);
  const { isOpenChangeCartModal } = useSelector((state) => state.modal);

  const deliveryAddressId = localStorage.getItem('deliveryAddressId')
  const invitationCode = localStorage.getItem('invitationCode')

  const backButton = () => {
    if(confirmationItem !== null) {
      dispatch(setConfirmationItem(null))
    }
    dispatch(closeChangeCartModal());
  };

  const handleClearCart = () => {
    if (cart) {
      setConfirmationLoading(true)
      dispatch(
        clearCart({
          id: cart.cart_id,
        })
      )
        .unwrap()
        .then((res) => {
          localStorage.removeItem('cartId')
          setConfirmationLoading(false)
          navigate("/");
          dispatch(clearOrderList());
          dispatch(closeChangeCartModal());
        })
        .catch((ex) => {
          setConfirmationLoading(false)
        });
    } else {
      navigate("/");
      dispatch(closeChangeCartModal());
    }
  };

  const handleDeleteCartItem = () => {
    if (confirmationItem !== null) {
      setConfirmationLoading(true)
      dispatch(updateCart({
        cart_item_id: confirmationItem,
        quantity: 0,
        remark: "",
      }))
      .unwrap()
      .then((res) => {
        dispatch(getCartCount())
        dispatch(
          getCartInfo({
            address_id: deliveryAddressId,
            // latitude: addressList?.find(
            //   (address) => address.id === parseInt(deliveryAddressId)
            // )?.latitude,
            // longitude: addressList?.find(
            //   (address) => address.id === parseInt(deliveryAddressId)
            // )?.longitude,
            // address: addressList?.find(
            //   (address) => address.id === parseInt(deliveryAddressId)
            // )?.address,
            invitation_code: invitationCode !== null ? invitationCode : "",
            delivery_method: "CAR",
          })
        )
        .catch((ex) => {
          if (ex && ex.response.status === 422) {
            const errors = ex.response.data.errors;
            if (errors && Object.keys(errors).length > 0) {
              Object.keys(errors).map((item, i) => {
                localStorage.removeItem('invitationCode')
                toast.error(
                  errors[item][0], {
                    theme: "light",
                    hideProgressBar: true,
                    closeOnClick: true,
                    closeButton: false,
                  }
                );
              });
            }
          }
        })
        dispatch(closeChangeCartModal())
        dispatch(setConfirmationItem(null))
        setConfirmationLoading(false)
        toast.success("Delete cart successful.", {
          autoClose: 1500,
          theme: "light",
          hideProgressBar: true,
          closeOnClick: true,
          closeButton: false,
        });
      })
      .catch((ex) => {
        setConfirmationLoading(false)
        if (ex && ex.response.status === 422) {
          const errors = ex.response.data.errors;
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) => {
              // setFieldError(item, errors[item]);
            });
          }
        }
      });
    }
  };

  const handleWithdrawal = async () => {
    setConfirmationLoading(true)
    try {
      const response = await apiServices.withdrawAmount({
          amount: confirmationItem?.amount
      });

      if(response) {
          dispatch(getWalletBalance())
          dispatch(setCallWalletHistory(true))
          setConfirmationLoading(false)
          dispatch(closeChangeCartModal());
          dispatch(setConfirmationItem(null))
          toast.success("Withdraw successful.", {
            autoClose: 1500,
            theme: "light",
            hideProgressBar: true,
            closeOnClick: true,
            closeButton: false,
          });
      }

    } catch (ex) {
      setConfirmationLoading(false)
      if (ex && ex.response.status === 422) {
          const errors = ex.response.data.errors;
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) => {
              toast.error(errors[item][0], {
                autoClose: 1500,
                theme: "light",
                hideProgressBar: true,
                closeOnClick: true,
                closeButton: false,
              });
            });
          }
      }
    };
  };

  useEffect(() => {
    setTop(true);
  }, [isOpenChangeCartModal]);

  useEffect(() => {
    if (top == true && modalRef.current) {
      scrollToTop(modalRef.current);
      setTop(false);
    }
  }, [top]);

  return (
    <FullModal
      centered
      scrollable
      staticBackdrop={true}
      show={isOpenChangeCartModal}
      backButton={backButton}
      screenSize={"xl"}
      dialogClassName="--margin"
      contentClassName="--short --white"
      content={
        <>
          <MDBModalBody ref={modalRef}>
            <MDBContainer className="change-cart-container">
              <MDBRow className="flex justify-center">
                <div className="image-frame --change">
                  <LazyLoadImage
                    src={Change} // || picture
                    alt="..."
                  />
                </div>
              </MDBRow>
              <MDBRow className="p-5 pt-0 pb-0">
                <div className="_label --proceed-change text-center mt-4">
                  {confirmationItem?.type ? 
                    'Withdraw Confirmation'
                  : confirmationItem !== null ? 
                    <span>Delete Confirmation</span> 
                  : 'Proceed to Change?'
                  }
                </div>

                {confirmationItem?.type ? 
                  <div className="_label --proceed-change-desc text-center mt-4">
                    Are you sure you want withdraw RM {confirmationItem.amount} from your wallet?
                  </div>
                : confirmationItem !== null ?
                  <div className="_label --proceed-change-desc text-center mt-4">
                    Are you sure you want to delete this cart item?
                  </div>
                :
                  <div className="_label --proceed-change-desc text-center mt-4">
                    This action will clear the items in your current order cart.
                    <br />
                    Do you want to proceed?
                  </div>
                }
              </MDBRow>
            </MDBContainer>
            <div className={`button-container --yesNo ${confirmationItem !== null && !confirmationItem?.type ? 'delete' : ''} `}>
              <Button 
                disabled={confirmationLoading}
                className=""
                onClick={() => confirmationItem?.type ? handleWithdrawal() : confirmationItem !== null ? handleDeleteCartItem() : handleClearCart()}
              >
                Yes
                {confirmationLoading && <Icon
                  icon="line-md:loading-twotone-loop"
                  width="25px"
                  height="25px"
                  color={confirmationItem !== null && !confirmationItem?.type ? "#fb636a" : "#fdfdff"}
                  className="ms-3 mb-1"
                />}
              </Button>
              <Button
                btnClassName={confirmationItem !== null && !confirmationItem?.type ? "--blue" : ""}
                variant={confirmationItem !== null && !confirmationItem?.type ? "solid" : "outline"}
                className="mt-3"
                onClick={() => backButton()}
              >
                No
              </Button>
            </div>
          </MDBModalBody>
        </>
      }
    />
  );
}
