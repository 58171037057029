//lib
import { Icon } from "@iconify/react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';

//reducers
import { useSelector } from "react-redux";

//hook
import useWindowDimensions from "../../../components/hook/use.window.dimensions";

//css
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

export default function PromoCarousel() {
    const { width } = useWindowDimensions();
    const { promotion_item, mobile_promotion_item } = useSelector(
        (state) => state.common
    );

    const handleRedirect = (url) => {
        window.location.href = url;
    };
      
    return (
        <div className="relative">
            <div 
                className="earth-icon mt-4 ms-4 flex justify-start pointer"
                onClick={() => handleRedirect(`${process.env.REACT_APP_MT_URL}`)}
            >
                <Icon
                    icon="ion:earth-sharp"
                    color="#305676" 
                    width={width > 991 ? 38 : 32}
                    height={width > 991 ? 38 : 32}
                />
            </div>
            <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                pagination={{
                    clickable: true,
                }}
                modules={[Autoplay, Pagination, Navigation]}
                className="promo-swiper"
            >
                {width > 550 ? 
                    promotion_item.length > 0 && promotion_item.map((promo, promoIndex) => (
                        <SwiperSlide>
                            <img src={promo.image} alt={promo.alt} />
                        </SwiperSlide>
                    ))
                : 
                    mobile_promotion_item.length > 0 && mobile_promotion_item.map((promo, promoIndex) => (
                        <SwiperSlide>
                            <img src={promo.image} alt={promo.alt} />
                        </SwiperSlide>
                    ))
                }
            </Swiper>
        </div>
    );
}
