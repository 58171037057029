import React, { useEffect, useState } from 'react';
import { MDBScrollspy, MDBScrollspyLink } from 'mdb-react-ui-kit';
import { useSearchParams } from 'react-router-dom';

export default function PolicyScroll({ containerRef, section1, section2, section3 }) {
  const [searchParams, setSearchParams] = useSearchParams();
  
  const selectedSection = searchParams.get("section")
  
  const [activeMenu, setActiveMenu] = useState(
    selectedSection === 'terms_and_conditions' ? 'Terms and Conditions' :
    selectedSection === 'privacy_policy' ? 'Privacy Policy' :
    selectedSection === 'referral_terms_and_conditions' ? '"Referral Terms and Conditions' :
    'Terms and Conditions'
  );
  
  const policyMenus = [
    "Terms and Conditions", 
    "Privacy Policy", 
    "Referral Terms and Conditions"
  ]

  const scrollToSection = (sectionRef) => {
    const navbarHeight = 100; // Replace with the actual height of your navbar
    if (sectionRef.current) {
      const position = sectionRef.current.offsetTop - navbarHeight;
      window.scrollTo({
        top: position,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    const scrollToSectionWithDelay = (sectionRef) => {
      setTimeout(() => {
        scrollToSection(sectionRef);
      }, 100); // 100 milliseconds delay
    };

    switch (selectedSection) {
      case 'terms_and_conditions':
        scrollToSectionWithDelay(section1);
        break;
      case 'privacy_policy':
        scrollToSectionWithDelay(section2);
        break;
      case 'referral_terms_and_conditions':
        scrollToSectionWithDelay(section3);
        break;
      default:
        break;
    }
  }, [selectedSection]);

  useEffect(() => {
    function handleScroll() {
      const section1Top = section1.current ? section1.current.offsetTop : 0;
      const section2Top = section2.current ? section2.current.offsetTop : 0;
      const section3Top = section3.current ? section3.current.offsetTop : 0;
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;

      if (scrollPosition >= section1Top - windowHeight * 0.35 && scrollPosition < section2Top - windowHeight * 0.35) {
        setActiveMenu("Terms and Conditions")
      } else if (scrollPosition >= section2Top - windowHeight * 0.35 && scrollPosition < section3Top - windowHeight * 0.35) {
        setActiveMenu("Privacy Policy")
      } else if (scrollPosition >= section3Top - windowHeight * 0.5) {
        setActiveMenu("Referral Terms and Conditions")
      }
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [section1, section2 , section3]);

  return (
    <>
      <MDBScrollspy container={containerRef} className="policy-scroll mt-2">
        <div onClick={() => scrollToSection(section1)} className={activeMenu === "Terms and Conditions" ? 'nav-item --active' : 'nav-item'}>
          Terms and Conditions
        </div>
        <div onClick={() => scrollToSection(section2)} className={activeMenu === "Privacy Policy" ? 'nav-item --active' : 'nav-item'}>
          Privacy Policy
        </div>
        <div onClick={() => scrollToSection(section3)} className={activeMenu === "Referral Terms and Conditions" ? 'nav-item --active' : 'nav-item'}>
          Referral Terms and Conditions
        </div>
      </MDBScrollspy>
    </>
  );
}