//lib
import Lottie from "lottie-react";
import { useDispatch, useSelector } from "react-redux";
import scrollToTop from "../../../helpers/scroll.to.top";
import useWindowDimensions from "../../../components/hook/use.window.dimensions";
import { useEffect, useRef, useState } from "react";
import FullModal from "../../../components/modal/full.modal.box";
import { MDBContainer, MDBModalBody, MDBRow, MDBCol } from "mdb-react-ui-kit";
import PaymentInfo from "./components/payment.info";
import { closeReceiptModal, openPurchaseHistoryModal, setAnimationModal } from "../../../redux/reducer/modalReducer";
import ModalHeader from "../../../components/header/modal.header";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getReceiptInfo } from "../../../redux/reducer/orderReducer";
import { jsPDF } from "jspdf";
import domtoimage from 'dom-to-image';
import { DotsLoading } from "../../../assets/lotties";

//assets

//reducers

//components

export default function Receipt() {
    const modalRef = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { width } = useWindowDimensions();

    const [ top, setTop ] = useState();

    const { receiptInfo, receiptInfoLoading, backPurchaseList } = useSelector((state) => state.order);
    const { isOpenReceiptModal } = useSelector((state) => state.modal);

    const backButton = () => {
        dispatch(closeReceiptModal())
        
        if(backPurchaseList) {
            dispatch(openPurchaseHistoryModal())
            dispatch(setAnimationModal(false))
            navigate(`/order?open=purchase_history`)
        } else {
            navigate('/order')
        }
    }

    const backToOrder = () => {
        navigate('/order')
        dispatch(closeReceiptModal())
    }

    const getDate = (dateTime) => {
        const dateObject = new Date(dateTime);

        const day = String(dateObject.getDate()).padStart(2, '0');
        const month = String(dateObject.getMonth() + 1).padStart(2, '0');
        const year = dateObject.getFullYear();
        
        const formattedDate = `${day}/${month}/${year}`;

        return formattedDate
    }

    const getImage = () => {
        const receiptContainer = document.querySelector(".receipt-container");

        if (receiptContainer && receiptInfo?.receipt_no !== null) {
          domtoimage.toPng(receiptContainer)
            .then((dataUrl) => {
              fetch(dataUrl)
                .then(res => res.blob())
                .then(blob => {
                  const file = new File([blob], `${receiptInfo.receipt_no}.png`, { type: 'image/png' });
      
                  const shareData = {
                    files: [file],
                    title: "Receipt",
                    text: "Here's the receipt."
                  };
      
                  if (navigator.canShare && navigator.canShare({ files: shareData.files })) {
                    navigator.share(shareData)
                      .then(() => console.log('Shared successfully.'))
                      .catch((error) => console.error('Sharing failed', error));
                  } else {
                    const link = document.createElement('a');
                    link.href = dataUrl;
                    link.download = 'receipt.png';
                    link.click();
                  }
                });
            })
            .catch((error) => {
              console.error('Failed to capture the receipt as an image', error);
            });
        } else {
          console.error("Receipt container not found");
        }
    };

    useEffect(() => {
        setTop(true);
    }, [isOpenReceiptModal]);
    
    useEffect(() => {
        if (top == true && modalRef.current) {
            scrollToTop(modalRef.current);
            setTop(false);
        }
    }, [top]);


    return (
        <FullModal
            centered
            scrollable
            staticBackdrop={true}
            show={isOpenReceiptModal}
            backButton={backToOrder}
            screenSize={width >= 991 ? "xl" : "fullscreen-xl-down"}
            contentClassName="--white"
            content={
                <>
                    <ModalHeader 
                        title="Receipt" 
                        backButton={backButton} 
                        backButtonNoAnimation={backButton} 
                        handleShareReceipt={getImage} 
                        shareButton 
                    />
                    <MDBModalBody ref={modalRef}>
                      {!receiptInfoLoading ?
                        <MDBContainer className="receipt-container" id="generate-receipt"> 
                            <MDBRow className="gap-3">
                                <div className="_label --outlet-name">
                                    {receiptInfo.merchant_name}
                                </div>
                                <div className="_label --outlet-info">
                                    {receiptInfo.merchant_address}
                                </div>
                                {receiptInfo.sales_service_tax > 0 && <div className="_label --outlet-info">
                                    SST ID : {receiptInfo.sst_no}
                                </div>}
                            </MDBRow>
                            <MDBRow className="dotted-line mt-4 gap-1">
                                <div className="_label --invoice">
                                    Invoice
                                </div>
                                <div className="_label --invoice-info mt-3">
                                    Order No : {receiptInfo.order_no}
                                </div>
                                <div className="_label --invoice-info">
                                    Receipt No : {receiptInfo.receipt_no}
                                </div>
                                <div className="_label --invoice-info">
                                    Date on : {!receiptInfoLoading ? getDate(receiptInfo.created_at) : '' }
                                </div>
                                <div className="mb-4" />
                            </MDBRow>
                            <MDBRow className="mt-4">
                                <div className="_label --invoice-info text-center">
                                    ***{receiptInfo.method}***
                                </div>
                                {receiptInfo.user_order?.length > 0 && receiptInfo.user_order.map((order, orderIndex) => (
                                    <div className="flex justify-between items-start _label --menu-info mt-4">
                                        <div>
                                            {order.quantity}
                                        </div>
                                        <div className="w-100 text-start ps-5">
                                            <div>{order.menu_name}</div>
                                            {order.details?.length > 0 && order.details.map((detail, detailIndex) => (
                                                <div className="_label --menu-details">{detail}</div>
                                            ))}
                                            {order.remark && <div className="_label --menu-details">{order.remark}</div>}
                                        </div>
                                        <div>
                                            {parseFloat(order.total_price).toFixed(2)}
                                        </div>
                                    </div>
                                ))}
                                <div className="mb-4" />
                            </MDBRow>
                            <MDBRow className="dotted-line mt-4 gap-1">
                                {receiptInfo.remark && 
                                    <>
                                        <div className="_label --invoice-info">
                                            Special Remark : {receiptInfo.remark}
                                        </div>
                                        <div className="mb-4" />
                                    </>
                                }
                            </MDBRow>
                            <MDBRow className="mt-4 gap-1">
                                <div className="flex items-baseline _label --invoice-info">
                                    <MDBCol className="col-9 p-0">
                                        <label>
                                            Subtotal
                                        </label>
                                    </MDBCol>
                                    <MDBCol className="col-3 text-end">
                                        <label>
                                            {!receiptInfoLoading  ? parseFloat(receiptInfo.subtotal).toFixed(2) : "0.00"}
                                        </label>
                                    </MDBCol>
                                </div>
                                {receiptInfo.discount > 0 && 
                                    <div className="flex items-baseline _label --invoice-info">
                                        <MDBCol className="col-9 p-0">
                                            <label>
                                                Discount
                                            </label>
                                        </MDBCol>
                                        <MDBCol className="col-3 text-end">
                                            <label>
                                                - {parseFloat(receiptInfo.discount).toFixed(2)}
                                            </label>
                                        </MDBCol>
                                    </div>
                                }
                                {receiptInfo.sales_service_tax > 0 && <div className="flex items-baseline _label --invoice-info">
                                    <MDBCol className="col-9 p-0">
                                        <label>
                                            SST (6%)
                                        </label>
                                    </MDBCol>
                                    <MDBCol className="col-3 text-end">
                                        <label>
                                            {parseFloat(receiptInfo.sales_service_tax).toFixed(2)}
                                        </label>
                                    </MDBCol>
                                </div>}
                                {receiptInfo.service_charge_percent !== 0 && <div className="flex items-baseline _label --invoice-info">
                                    <MDBCol className="col-9 p-0">
                                        <label>
                                            Service Charge ({!receiptInfoLoading ? receiptInfo.service_charge_percent : 0}%)
                                        </label>
                                    </MDBCol>
                                    <MDBCol className="col-3 text-end">
                                        <label>
                                            {!receiptInfoLoading ? parseFloat(receiptInfo.service_charge).toFixed(2) : "0.00"}
                                        </label>
                                    </MDBCol>
                                </div>}
                                {receiptInfo.method === 'Delivery' && receiptInfo.delivery_fees !== null &&
                                    <div className="flex items-baseline _label --invoice-info">
                                        <MDBCol className="col-9 p-0">
                                            <label>
                                                Delivery Fees
                                            </label>
                                        </MDBCol>
                                        <MDBCol className="col-3 text-end">
                                            <label>
                                                {!receiptInfoLoading ? parseFloat(receiptInfo.delivery_fees).toFixed(2) : "0.00"}
                                            </label>
                                        </MDBCol>
                                    </div>
                                }
                            </MDBRow>
                            <MDBRow>
                                <div className="mt-4 _label --total-paid">
                                    Total Paid: RM {!receiptInfoLoading  ? parseFloat(receiptInfo.total_price).toFixed(2) : "0.00"}
                                </div>
                            </MDBRow>
                        <PaymentInfo paymentInfo={receiptInfo} />
                        </MDBContainer>
                      : 
                        <div className="lottie-loading flex justify-center" style={{ paddingTop: '18em' }}>
                            <div>
                                <div className="lottie-frame --receipt flex justify-content-center w-100">
                                    <div className="image-frame">
                                        <Lottie animationData={DotsLoading} />
                                    </div>
                                </div>
                            </div>
                        </div>
                      }
                    </MDBModalBody>
                </>
            }
        />
    );
}