//service
import http from "./http.services";

// store
async function getOutletList(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.get(
    process.env.REACT_APP_API_URL +
      `/merchant_list?latitude=${payload.latitude}&longitude=${payload.longitude}`
  );
  return response.data;
} // less outlet name, if no address, then how

async function getGuestToken() {
  http.setToken(localStorage.getItem("token"));
  const response = await http.post(process.env.REACT_APP_API_URL + "/guest");
  return response.data;
}

async function getAddressList(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.get(process.env.REACT_APP_API_URL + `/address`);
  return response.data;
}

async function getAddressInfo(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.get(
    process.env.REACT_APP_API_URL + `/address/${payload}`
  );
  return response.data;
}

async function createAddress(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.post(
    process.env.REACT_APP_API_URL + `/address`,
    payload
  );
  return response.data;
}

async function updateAddress(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.put(
    process.env.REACT_APP_API_URL + `/address/${payload.id}`,
    payload
  );
  return response.data;
}

async function deleteAddress(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.delete(
    process.env.REACT_APP_API_URL + `/address/${payload.id}`
  );
  return response.data;
}

// menu
async function getOutletInfo(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.get(
    process.env.REACT_APP_API_URL + `/merchant_card/${payload.id}`
  );
  return response.data;
}

async function getMenuList(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.get(
    process.env.REACT_APP_API_URL +
      `/display_menu?merchant_id=${payload.merchant_id}`
  );
  return response.data;
}

async function getCategoryList(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.get(
    process.env.REACT_APP_API_URL +
      `/category_listing?merchant_id=${payload.merchant_id}`
  );
  return response.data;
} // category de image how

async function getMenuInfo(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.get(
    process.env.REACT_APP_API_URL + `/option_listing/${payload.id}`
  );
  return response.data;
} // category de image how

//checkout
async function getCartInfo(payload) {
  http.setToken(localStorage.getItem("token"));
  const shipping = localStorage.getItem("shipping");
  const response =
    shipping === "Delivery"
      ? await http.post(
          process.env.REACT_APP_API_URL +
            `/cart/display_cart?address_id=${payload.address_id ?? ""}&invitation_code=${
             payload.invitation_code}&delivery_method=${payload.delivery_method ?? ""}`
        )
      : await http.post(
          process.env.REACT_APP_API_URL +
            `/cart/display_cart?invitation_code=${payload.invitation_code}`
        );
  return response.data;
}

async function addCart(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.post(
    process.env.REACT_APP_API_URL + `/cart`,
    payload
  );
  return response.data;
}

async function clearCart(payload) {
  // clear whole cart
  http.setToken(localStorage.getItem("token"));
  const response = await http.delete(
    process.env.REACT_APP_API_URL + `/cart/${payload.id}`
  );
  return response.data;
}

async function updateCart(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.post(
    process.env.REACT_APP_API_URL + `/cart/update_cart`,
    payload
  );
  return response.data;
}

async function verifyUserContact(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.post(
    process.env.REACT_APP_API_URL + `/verify_user_if_exist`,
    payload
  );
  return response.data;
}

async function createOrder(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.post(
    process.env.REACT_APP_API_URL + `/transfer_cart_into_order`,
    payload
  );
  return response.data;
}

async function getProviderList(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.get(
    process.env.REACT_APP_API_URL + `/get_tenant_channel_id/${payload.id}?merchant_id=${payload.merchant_id}`,
    payload
  );
  return response.data;
}

async function getBankList(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.get(
    process.env.REACT_APP_API_URL + `/get_provider_channel_id/${payload.id}?merchant_id=${payload.merchant_id}`,
    payload
  );
  return response.data;
}

async function getAllPayments(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.get(
    process.env.REACT_APP_API_URL + `/get_all_channels?merchant_id=${payload.merchant_id}`,
    payload
  );
  return response.data;
}

async function generatePayment(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.post(
    process.env.REACT_APP_API_URL + `/transaction/generate_order_payment`,
    payload
  );
  return response.data;
}

//order
async function getOrderList(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.get(process.env.REACT_APP_API_URL + `/order`);
  return response.data;
}

async function getProfile(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.get(process.env.REACT_APP_API_URL + `/profile`);
  return response.data;
}

async function updateProfile(payload) {
  http.setToken(localStorage.getItem('token'))
  const response = await http.post(process.env.REACT_APP_API_URL + `/update_profile`, payload)
  return response.data
}

async function getPurchaseHistory(payload) {
  http.setToken(localStorage.getItem('token'))
  const response = await http.post(process.env.REACT_APP_API_URL + `/purchase_history`, payload)
  return response.data
}

async function getReceiptInfo(payload) {
  http.setToken(localStorage.getItem("token"));
  const response = await http.post(
    process.env.REACT_APP_API_URL + `/history_detail`,
    payload
  );
  return response.data;
}

async function getWalletHistory(payload) {
  http.setToken(localStorage.getItem('token'))
  const response = await http.post(process.env.REACT_APP_API_URL + `/get_wallet_history`, payload)
  return response.data
}

async function getCartCount(payload) {
  http.setToken(localStorage.getItem('token'))
  const response = await http.get(process.env.REACT_APP_API_URL + `/count_cart_item`)
  return response.data
}

async function withdrawAmount(payload) {
  http.setToken(localStorage.getItem('token'))
  const response = await http.post(process.env.REACT_APP_API_URL + `/withdraw_wallet`, payload)
  return response.data
}

async function getWalletBalance(payload) {
  http.setToken(localStorage.getItem('token'))
  const response = await http.post(process.env.REACT_APP_API_URL + `/get_wallet_balance`, payload)
  return response.data
}

async function getAnnouncement(payload) {
  http.setToken(localStorage.getItem('token'))
  const response = await http.get(process.env.REACT_APP_API_URL + `/announcement_listing?merchant_id=${payload.id}`, payload)
  return response.data
}

const apiService = {
    getOutletList,
    getGuestToken,
    getAddressList,
    getAddressInfo, 
    createAddress,
    updateAddress,
    deleteAddress,
    getOutletInfo,
    getMenuList,
    getCategoryList,
    getMenuInfo,
    getCartInfo,
    addCart,
    clearCart,
    updateCart,
    verifyUserContact,
    createOrder,
    getProviderList,
    getBankList,
    generatePayment,
    getOrderList,
    getProfile,
    updateProfile,
    getReceiptInfo,
    getWalletHistory,
    getPurchaseHistory,
    getCartCount,
    withdrawAmount,
    getWalletBalance,
    getAnnouncement,
    getAllPayments
};

export default apiService;
