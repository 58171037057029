//lib

import { Icon } from "@iconify/react";
import { MDBRow } from "mdb-react-ui-kit";

//assets

//reducers

//components

export default function IconButton({ 
  icon, 
  width, 
  height, 
  onClick, 
  className
}) {
  return (
    <MDBRow className={`common _icon-button ${className ? className : ''}`}>
        <div 
          className="flex justify-center items-center pointer"
          onClick={onClick || undefined}
        >
            <Icon 
                icon={icon}
                color="#FDFDFF" 
                width={width ? width : 35} 
                height={height ? height : 35}
            />
        </div>
    </MDBRow>
  );
}
