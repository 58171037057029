import * as Yup from "yup";
import {
  MDBCol,
  MDBRow,
  MDBModalBody,
  MDBContainer,
  MDBCheckbox,
} from "mdb-react-ui-kit";
import { useState, useEffect } from "react";
import { Skeleton } from "primereact/skeleton";
import { Formik, Form, FieldArray, Field } from "formik";
import { RemoveScroll } from "react-remove-scroll";

//api
import useWindowDimensions from "../../../components/hook/use.window.dimensions";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "../../../components/common/icon.button";
import { LazyLoadImage } from "react-lazy-load-image-component";
import FullModal from "../../../components/modal/full.modal.box";
import { closeCartModal } from "../../../redux/reducer/modalReducer";
import Input from "../../../components/element/input";
import Button from "../../../components/element/button";
import { Icon } from "@iconify/react";
import Checkbox from "../../../components/element/checkbox";
import InputCount from "../../../components/element/input.count";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  deleteOptionPrice,
  getMenuInfo,
  setQuantity,
} from "../../../redux/reducer/menuReducer";
import { DefaultCenter } from "../../../assets/images";
import apiServices from "../../../services/api.services";
import {
  getCartCount,
  getCartInfo,
  setAddCartLoading,
  setEditCartData,
  setUpdateCartLoading,
} from "../../../redux/reducer/checkoutReducer";
import { getAddressList } from "../../../redux/reducer/storeReducer";
import { toast } from "react-toastify";

//component

//redux

export default function AddToCart() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();

  const {
    menuId,
    menuInfo,
    quantity,
    optionPrice,
    menuInfoLoading,
  } = useSelector((state) => state.menu);
  const { addressList } = useSelector((state) => state.common);
  const { editCartData, addCartLoading, updateCartLoading } = useSelector((state) => state.checkout);
  const { isOpenCartModal } = useSelector((state) => state.modal);

  const [searchParams, setSearchParams] = useSearchParams();

  const [openOptionItems, setOpenOptionsItems] = useState([]);
  const [quantityChange, setQuantityChange] = useState(false);

  const option_list = menuInfo.option_listing ? menuInfo.option_listing : [];

  const shipping = localStorage.getItem("shipping");
  const invitationCode = localStorage.getItem("invitationCode");
  const deliveryAddressId = localStorage.getItem("deliveryAddressId");

  const cartForm = {
    menu_id: menuId,
    quantity: editCartData.quantity ? editCartData.quantity : 1,
    remark: "",
    method: "",
    option: [],
  };

  const calculateOptionPrices = (values, menuInfo) => {
    let optionPriceTotal = 0;

    if (menuInfo.option_listing?.length > 0) {
      menuInfo.option_listing.forEach((option) => {
        const selectedOptionItems = values[option.option_id];
        if (selectedOptionItems) {
          selectedOptionItems.forEach((itemId) => {
            const item = option.option_item.find(
              (item) => item.option_item_id === itemId
            );
            if (item) {
              optionPriceTotal += parseFloat(item.option_item_price);
            }
          });
        }
      });
    }
    return parseFloat(optionPriceTotal);
  };

  const backButton = () => {
    dispatch(closeCartModal());
    dispatch(setEditCartData({}))
    setSearchParams('')
  };

  const handleShowOptionItems = (optionId) => {
    if (openOptionItems.includes(optionId)) {
      setOpenOptionsItems(openOptionItems.filter((id) => id !== optionId));
    } else {
      setOpenOptionsItems([...openOptionItems, optionId]);
    }
  };

  const initialAddData =
    option_list.length > 0
      ? option_list.reduce(
          (acc, group) => ({
            ...acc,
            ...cartForm,
            [group.option_id]: [],
          }), {})
      : cartForm;

  const initialEditData = {
    ...cartForm,
    ...editCartData?.details?.length > 0 &&
      editCartData.details.reduce((acc, item) => {
        if (!acc[item.option_id]) {
          acc[item.option_id] = [];
        }
        acc[item.option_id].push(item.option_item_id);
        return acc;
      }, {})
  };

  const validationSchema = Yup.object().shape(
    option_list.reduce(
      (acc, group) => ({
        ...acc,
        [group.option_id]: Yup.array()
          .min(
            group.option_item_min,
            `Minimum select ${group.option_item_min}`
          )
          .max(
            group.option_item_max,
            `Maximum select  ${group.option_item_max}`
          ),
      }),
      {}
    )
  );

  const handleSubmit = async ({ values, setFieldError }) => {
    let newOption = [];
    for (var key in option_list) {
      values[option_list[key].option_id].map((item) =>
        newOption.push({ option_item_id: Number(item) })
      );
    }

    try {
      if(editCartData.cart_item_id) {
        dispatch(setUpdateCartLoading(true))
        const response = await apiServices.updateCart({
          cart_item_id: editCartData.cart_item_id,
          quantity: quantity ? quantity : "",
          remark: values.remark,
          option: newOption,
        });

        if (response) {
          dispatch(closeCartModal());
          setSearchParams('')
          dispatch(getAddressList())
          .unwrap()
          .then((res) => {
            dispatch(setEditCartData({}))
            dispatch(getCartInfo({
              address_id: deliveryAddressId,
              invitation_code: invitationCode !== null ? invitationCode : '',
              delivery_method: 'CAR',
            }))
            .unwrap()
            .then((res) => {
              dispatch(setUpdateCartLoading(false))
            })
            dispatch(getCartCount());
          })
        }
      } else {
        dispatch(setAddCartLoading(true))
        const response = await apiServices.addCart({
          menu_id: menuId,
          quantity: quantity ? quantity : "",
          remark: values.remark,
          method: shipping === "Pickup" ? 2 : 3,
          option: newOption,
        });

        if (response) {
          dispatch(closeCartModal());
          setSearchParams('')
          dispatch(getCartCount());
          dispatch(getCartInfo({
            address_id: deliveryAddressId,
            invitation_code: invitationCode !== null ? invitationCode : '',
            delivery_method: 'CAR',
          }))
          .then((res) => {
            if(res.payload.data.carts?.length > 0) {
              localStorage.setItem('cartId', res.payload.data.carts[0].cart_id)
            }
            dispatch(setAddCartLoading(false))
          })
          .catch((ex) => {
            if (ex && ex.response.status === 422) {
              const errors = ex.response.data.errors;
              if (errors && Object.keys(errors).length > 0) {
                Object.keys(errors).map((item, i) => {
                  localStorage.removeItem('invitationCode')
                  toast.error(
                    errors[item][0], {
                      theme: "light",
                      hideProgressBar: true,
                      closeOnClick: true,
                      closeButton: false,
                    }
                  );
                });
              }
            }
          })
        }
      }
    } catch (ex) {
      // setAddingOrder(false)
      dispatch(setEditCartData({}))
      dispatch(setAddCartLoading(false))
      dispatch(setUpdateCartLoading(false))
      if (ex && Object.keys(ex).length > 0) {
        let errorMsg = [];
        if (ex.response.status === 422) {
          const errors = ex.response.data.errors;
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) => {
              // errorMsg = [errors[item]];
              // setFieldError(item, errors[item]);
              errorMsg = errors[item][0];
              toast.error(errorMsg,
                {
                  theme: "light",
                  hideProgressBar: true,
                  closeOnClick: true,
                  closeButton: false,
                }
              );
            });
          }
        }
      }
    }
  };

  useEffect(() => {
    if(editCartData?.menu_id) {
      dispatch(
        getMenuInfo({
          id: editCartData?.menu_id,
        })
      );
    }
    dispatch(setQuantity(1))
  }, []);

  return (
    <FullModal
      centered
      scrollable
      staticBackdrop={true}
      show={isOpenCartModal}
      backButton={backButton}
      screenSize={width >= 991 ? "xl" : "fullscreen-xl-down"}
      contentClassName=""
      content={
        <MDBModalBody>
          <Formik
            initialValues={editCartData.cart_item_id ? initialEditData : initialAddData}
            validationSchema={validationSchema}
            enableReinitialize={true}
            validateOnMount={!menuInfoLoading}
            onSubmit={(values, { setFieldError, resetForm }) => {
              handleSubmit({ values, setFieldError });
              // resetForm({ values: "" });
            }}
          >
            {({ isSubmitting, setFieldValue, values, errors, isValid }) => (
              <Form>
                <MDBContainer className="cart-container">
                  <MDBRow>
                    <div className="flex justify-between items-center">
                      <label className="_label --place-order">
                        {editCartData.cart_item_id ? 'Update' : 'Place'} Order
                      </label>
                      <IconButton
                        icon="akar-icons:cross"
                        width="18"
                        height="20"
                        onClick={() => backButton()}
                      />
                    </div>
                  </MDBRow>
                  <MDBCol className="image-col">
                    <div className="image-frame --food">
                      {!menuInfoLoading ? (
                        <LazyLoadImage
                          src={menuInfo.picture || DefaultCenter} // || picture
                          alt="..."
                        />
                      ) : (
                        <Skeleton
                          width="360px"
                          height="194px"
                          borderRadius="1.2em"
                          className="blue-skeleton"
                        />
                      )}
                    </div>
                  </MDBCol>
                  <>
                    <MDBContainer
                      style={{ overflow: "auto", marginBottom: "0em" }}
                    >
                      <MDBRow
                        className={`flex ${
                          !menuInfoLoading ? "items-baseline" : ""
                        } mb-5`}
                      >
                        <MDBCol className="col-8">
                          {!menuInfoLoading ? (
                            <>
                              <div className="_label --menu-name">
                                {menuInfo ? menuInfo.name : ""}
                              </div>
                              <div className="_label --menu-desc mt-1">
                                {menuInfo ? menuInfo.description : ""}
                              </div>
                            </>
                          ) : (
                            <>
                              <div>
                                <Skeleton
                                  width={width > 550 ? "20em" : "70%"}
                                  height="1.4em"
                                  className="blue-skeleton"
                                />
                              </div>
                              <div>
                                <Skeleton
                                  width={width > 550 ? "28em" : "90%"}
                                  height="1.1em"
                                  className="mt-4 blue-skeleton"
                                />
                              </div>
                            </>
                          )}
                        </MDBCol>
                        <MDBCol className="text-end">
                          {!menuInfoLoading ? (
                            <>
                              <div className="_label --menu-price">
                                {menuInfo && menuInfo.unit_price > 0
                                  ? "RM " +
                                    parseFloat(menuInfo.unit_price).toFixed(2)
                                  : "RM 0.00"}
                              </div>
                              <div className="_label --base-price">
                                Base price
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="flex justify-end">
                                <Skeleton
                                  width={width > 550 ? "8em" : "70%"}
                                  height="1.4em"
                                  className="blue-skeleton"
                                />
                              </div>
                              <div className="_label --base-price mt-3">
                                Base price
                              </div>
                            </>
                          )}
                        </MDBCol>
                      </MDBRow>
                      <div>
                        <FieldArray
                          name="option"
                          render={(arrayHelpers) => (
                            <MDBRow className="mb-5">
                              {!menuInfoLoading &&
                              menuInfo.option_listing?.length > 0
                                ? menuInfo.option_listing.map(
                                    (option, index) => {
                                      const max = option.option_item_max;

                                      return (
                                        <MDBContainer
                                          className="addon-listing"
                                          key={index}
                                        >
                                          <MDBRow
                                            className="addon-row pointer"
                                            onClick={() =>
                                              handleShowOptionItems(
                                                option.option_id
                                              )
                                            }
                                          >
                                            <MDBCol className="col-7 p-0">
                                              <label className="_label --addon-title pointer">
                                                {option.option_name} &nbsp;
                                                <span>
                                                  Option, max{" "}
                                                  {option.option_item_max}
                                                </span>
                                              </label>
                                            </MDBCol>
                                            <MDBCol className="col-4 flex justify-end items-baseline">
                                              {errors &&
                                              errors[option.option_id] ? (
                                                <div className="element _errors text-right no-padding error-message text-end">
                                                  <label style={{ fontSize: width > 550 ? '12px' : '11px', fontFamily: 'cabin-semibold', paddingTop: width > 550 ? '' : '0.5em' }}>
                                                    {errors[option.option_id]}
                                                  </label>
                                                </div>
                                              ) : null}
                                            </MDBCol>
                                            <MDBCol className="col-1">
                                              <Icon
                                                icon={openOptionItems.includes(
                                                  option.option_id
                                                ) ? "octicon:chevron-up-16" : "octicon:chevron-down-16" }
                                                color={"#F9A01B"}
                                                width={20}
                                                height={20}
                                              />
                                            </MDBCol>
                                          </MDBRow>
                                          <div
                                            style={{
                                              height: openOptionItems.includes(
                                                option.option_id
                                              )
                                                ? "auto"
                                                : 0,
                                              overflow: "hidden",
                                            }}
                                          >
                                            {option.option_item.map(
                                              (item, index) => {
                                                if (!values[option.option_id]) {
                                                  values[option.option_id] = [];
                                                }

                                                return (
                                                  <MDBRow
                                                    className="addon-row"
                                                    key={index}
                                                  >
                                                    <MDBCol className="col-10 pointer">
                                                      <Checkbox
                                                        disabled={
                                                          values[
                                                            option.option_id
                                                          ].length === max &&
                                                          max !== 1 &&
                                                          !values[
                                                            option.option_id
                                                          ].includes(
                                                            option.option_item_id
                                                          )
                                                        }
                                                        onChange={(e) => {
                                                          if (e.target.checked) {
                                                            if (values[option.option_id].length === max) {
                                                              setFieldValue(option.option_id, [item.option_item_id]);
                                                            } else {
                                                              setFieldValue(option.option_id, [...values[option.option_id], item.option_item_id,]
                                                              );
                                                            }
                                                          } else {
                                                            setFieldValue( option.option_id, values[option.option_id].filter((a) => a !== item.option_item_id)
                                                            );
                                                          }
                                                        }}
                                                        type="checkbox"
                                                        name={option.option_id}
                                                        value={
                                                          item.option_item_id
                                                        }
                                                        checked={values[option.option_id]? values[option.option_id].includes(item.option_item_id) : false}
                                                        labelValue={item.option_item_name}
                                                      />
                                                    </MDBCol>
                                                    <MDBCol>
                                                      <label className="_label --addon-price">
                                                        +{" "}
                                                        {parseFloat(
                                                          item.option_item_price
                                                        ).toFixed(2)}
                                                      </label>
                                                    </MDBCol>
                                                  </MDBRow>
                                                );
                                              }
                                            )}
                                          </div>
                                        </MDBContainer>
                                      );
                                    }
                                  )
                                : ""}
                            </MDBRow>
                          )}
                        />
                        {/* <MDBRow className="note-row">
                          <label className="_label --note">
                            Note to our chef &nbsp;
                            <span>Optional</span>
                          </label>
                          <Input
                            name="remark"
                            placeholder="e.g no cream "
                            as="line-field"
                            inputClassName="--white"
                          />
                        </MDBRow> */}
                      </div>
                    </MDBContainer>
                  </>
                </MDBContainer>
                <MDBRow className="count_button"></MDBRow>
                <MDBRow className="button-container m-0">
                  <InputCount
                    className="mb-4"
                    quantity={editCartData.quantity ? editCartData.quantity : quantity }
                    setQuantity={setQuantity}
                    max={menuInfo.quantity_balance !== null ? menuInfo.quantity_balance : null
                      // menuInfo.quantity_balance !== null
                      //   ? menuInfo.quantity_balance - maxfoodQuantity
                      //   : null
                    }
                    setQuantityChange={setQuantityChange}
                  />
                  <Button
                    // type="text"
                    disabled={
                      menuInfoLoading || addCartLoading || updateCartLoading || !isValid
                      // option.quantity_balance !== null &&
                      // option.quantity_balance <= maxfoodQuantity
                      //   ? true
                      //   : false || loading
                      //   ? true
                      //   : false || quantity == 0
                      //   ? true
                      //   : false
                    }
                    loading={false} //addingOrder
                    type="submit"
                    // onClick={() => handleRedirect()}
                  >
                    {editCartData.cart_item_id ? 'Update' : 'Add to'} Cart - RM{" "}
                    {menuInfo && menuInfo.unit_price > 0 && !menuInfoLoading
                      ? (
                          parseFloat(menuInfo.unit_price * (quantityChange ? quantity : editCartData.quantity ? editCartData.quantity : quantity)) +
                          calculateOptionPrices(values, menuInfo) * (quantityChange ? quantity : editCartData.quantity ? editCartData.quantity : quantity)
                        ).toFixed(2) //parseFloat(menuInfo.unit_price * quantity).toFixed(2)
                      : "0.00"}
                    {(addCartLoading || updateCartLoading) && <Icon
                      icon="line-md:loading-twotone-loop"
                      width="25px"
                      height="25px"
                      color="#fdfdff"
                      className="ms-3 mb-1"
                    />}
                  </Button>
                </MDBRow>
              </Form>
            )}
          </Formik>
        </MDBModalBody>
      }
    />
  );
}
