import { useDispatch } from "react-redux";
import { setOutletLoading } from "../redux/reducer/storeReducer";

// locationHelper.js

export const getLocation = (
  callback,
  defaultLocation = {
    latitude: localStorage.getItem("lat"),
    longitude: localStorage.getItem("lng"), 
  },
  dispatch
) => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        localStorage.setItem("lat", position.coords.latitude);
        localStorage.setItem("lng", position.coords.longitude);

        callback({
          latitude: String(position.coords.latitude),
          longitude: String(position.coords.longitude),
        });
      },
      () => {
        // Error or permission denied
        callback(defaultLocation);
      }
    );
  } else {
    // Geolocation is not supported
    callback(defaultLocation);
  }
};
