import { useEffect } from "react";

//lib
import { Icon } from "@iconify/react";
import { MDBRow } from "mdb-react-ui-kit";
import { toast } from "react-toastify";

//assets

//reducers
import { useDispatch, useSelector } from "react-redux";
import {
  closeSetAddressModal,
  openSaveAddressModal,
} from "../../../../redux/reducer/modalReducer";
import {
  deleteAddress,
  getAddressList,
  setAddressId,
  setAddressList,
  getAddressInfo,
} from "../../../../redux/reducer/storeReducer";

//components
import ActionMenu from "../../../../components/element/action.menu";
import useWindowDimensions from "../../../../components/hook/use.window.dimensions";
import { setDeliveryAddressId } from "../../../../redux/reducer/commonReducer";
import { Skeleton } from "primereact/skeleton";
import { useSearchParams } from "react-router-dom";

export default function AddressListing({ addresses }) {
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();

  const [searchParams, setSearchParams] = useSearchParams();

  const { addressMenu, addressList, addressListLoading } = useSelector((state) => state.store);

  const handleRedirect = (id, address) => {
    localStorage.setItem("deliveryAddressId", id);
    localStorage.setItem("savedAddresses", JSON.stringify(address));
    dispatch(closeSetAddressModal());
    dispatch(setDeliveryAddressId());
    setSearchParams('')

  };

  const handleChangeAddress = (id) => {
    dispatch(openSaveAddressModal());
    dispatch(getAddressInfo(id));
    dispatch(setAddressId(id))
    setSearchParams('')
  };

  const handleDeleteAddress = (id) => {
    dispatch(deleteAddress({
      id: id
    })).then(() => {
      dispatch(getAddressList());
      toast.success("Delete address successful.", {
        autoClose: 1500,
        theme: "light",
        hideProgressBar: true,
        closeOnClick: true,
        closeButton: false,
      });
    });

    const existingAddresses =
      JSON.parse(localStorage.getItem("savedAddresses")) || [];
    const index = existingAddresses.findIndex((address) => address.id === id);

    if (index !== -1) {
      existingAddresses.splice(index, 1);
      localStorage.setItem("savedAddresses", JSON.stringify(existingAddresses));
      dispatch(setAddressList());
      toast.success("Address deleted successfully", {
        autoClose: 1500,
        theme: "light",
        hideProgressBar: true,
        closeOnClick: true,
        closeButton: false,
      });
    }
  };

  return (
    <MDBRow className="address-listing mt-4">
      <label className="_label --saved-title mt-4">Saved Address</label>
      <div className="p-4">
        {!addressListLoading ?
          addresses?.length > 0 ? (
          addresses.map((address, addressIndex) => (
            <>
              <MDBRow className="mt-4 flex addresses">
                <div
                  className="col-9 pointer"
                  onClick={() => {
                    handleRedirect(address.id, address);
                  }}
                >
                  <label className="_label --address-name pointer">
                    {address.name}
                  </label>
                  <div className="_label --address mt-2">{address.address}</div>
                </div>
                <div
                  className="col-3 flex justify-end items-center relative"
                  //onClick={() => handleChangeAddress(address)}
                >
                  <ActionMenu
                    menus={addressMenu}
                    left={width > 991 ? -11 : ""}
                    handleEdit={handleChangeAddress}
                    handleDelete={handleDeleteAddress}
                    item={address.id}
                  >
                    <Icon
                      icon="bi:three-dots-vertical"
                      color={"#fdfdff"}
                      width="18"
                      height="20"
                      className="pointer"
                    />
                  </ActionMenu>
                  {/* <div className="menu">
                                <div>Edit</div>
                                <div>Delete</div>
                            </div> */}
                </div>
                <div className="mb-4" />
              </MDBRow>
              {/* {addressIndex === addresses.length - 1 && (
                <div
                  className="_label --add-address mt-4 flex items-center justify-start"
                  onClick={() => handleAddAddress()}
                >
                  <Icon icon="ic:round-plus" width={18} height={18} />
                  Add New Address
                </div>
              )} */}
            </>
          ))
          ) : (
            <div className="empty-address">
              <Icon
                icon="carbon:location"
                color="#9EBCD8"
                width={28}
                height={28}
              />
              <div className="_label --saved-desc mt-2">
                Add your address and have your <br />
                order delivered to your doorstep
              </div>
              {/* <div
                className="_label --add-address mt-5 flex items-center justify-center"
                onClick={() => handleAddAddress()}
              >
                <Icon icon="ic:round-plus" width={18} height={18} />
                Add New Address
              </div> */}
            </div>
          )
          : 
            Array.from({ length: 3 }, (_, addressListIndex) => (
              <MDBRow className="mt-4 flex addresses">
                <div className="col-9 pointer">
                  <label className="_label --address-name pointer">
                    <Skeleton
                      width="8em"
                      height="1.2em"
                      className="blue-skeleton"
                    />
                  </label>
                  <div className="_label --address mt-3 mb-2">
                    <Skeleton
                      width="100%"
                      height="0.9em"
                      className="blue-skeleton"

                    />
                    <Skeleton
                      width="80%"
                      height="0.9em"
                      className="mt-3 blue-skeleton"
                    />
                  </div>
                </div>
                <div className="col-3 flex justify-end items-center relative">

                </div>
                <div className="mb-4" />
              </MDBRow>
            ))
        }
      </div>
    </MDBRow>
  );
}
