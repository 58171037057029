//lib

import { Icon } from "@iconify/react";
import { MDBContainer, MDBRow } from "mdb-react-ui-kit";
import { useDispatch, useSelector } from "react-redux";
import StoreHeader from "./components/store.header";
import Announcement from "./components/announcement";
import CategoryBar from "./components/category.bar";
import MenuListing from "./components/menu.listing";
import AddToCart from "./modals/add.to.cart";
import scrollToTop from "../../helpers/scroll.to.top";
import { useEffect } from "react";
import { getAnnouncement, getCategoryList, getMenuInfo, getMenuList, getOutletInfo } from "../../redux/reducer/menuReducer";
import { setOutletId } from "../../redux/reducer/commonReducer";
import { getCartCount, getCartInfo } from "../../redux/reducer/checkoutReducer";
import { toast } from "react-toastify";
import { useNavigate, useSearchParams } from "react-router-dom";
import ConfirmationModal from "../../components/common/confirmation.modal";
import ReOrder from "./modals/re.order";
import Button from "../../components/element/button";
import { openCartModal } from "../../redux/reducer/modalReducer";

//assets

//reducers

//components

export default function Menu() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams();

  const { deliveryAddressId } = useSelector((state) => state.common)
  const { outletId, addressList } = useSelector((state) => state.store)
  const { cartInfo, cartCounts, addCartLoading } = useSelector((state) => state.checkout)
  const { categoryList, categoryLoading, menuList, outletInfo, menuLoading, outletInfoLoading, announcementList } = useSelector((state) => state.menu)
  const { isOpenCartModal, isOpenChangeCartModal, isOpenReOrderModal } = useSelector((state) => state.modal)

  const merchantId = localStorage.getItem("outletId");
  const shipping = localStorage.getItem('shipping')
  const invitationCode = localStorage.getItem("invitationCode");

  const openModal = searchParams.get("open")
  const openModalId = searchParams.get("id")

  const handleToCart = () => {
    navigate('/cart')
  }

  useEffect(() => {
    if(openModal === 'add_cart' && openModalId !== null) {
      dispatch(openCartModal())
      dispatch(
        getMenuInfo({
          id: openModalId,
        })
      );
    }
  }, [openModal])

  useEffect(() => {
    scrollToTop()

    dispatch(setOutletId())

    dispatch(getCartCount());

    
    if(merchantId) {
      dispatch(getAnnouncement({
        id: merchantId,
      }))

      dispatch(getOutletInfo({
        id: merchantId,
      }))
  
      dispatch(getMenuList({
        merchant_id: merchantId,
      }))
  
      dispatch(getCategoryList({
        merchant_id: merchantId,
      }))
      .unwrap()
      .then((res) => {
      })
      .catch((ex) => {
        if (ex && ex.response.status === 422) {
          toast.error("Merchant is currently unavailable.", {
            theme: "light",
            hideProgressBar: true,
            closeOnClick: true,
            closeButton: false,
          });
          navigate('/')
        }
      });

      dispatch(getCartInfo({
        address_id: deliveryAddressId,
        invitation_code: invitationCode !== null ? invitationCode : '',
        delivery_method: 'CAR',
      }))
      .unwrap()
      .then((res) => {
        if(res.data.carts.length > 0) {
          localStorage.setItem('cartId', res.data.carts[0].cart_id)
        }
      })
      .catch((ex) => {
        if (ex && ex.response.status === 422) {
          const errors = ex.response.data.errors;
          if (errors && Object.keys(errors).length > 0) {
            Object.keys(errors).map((item, i) => {
              localStorage.removeItem('invitationCode')
              toast.error(
                errors[item][0], {
                  theme: "light",
                  hideProgressBar: true,
                  closeOnClick: true,
                  closeButton: false,
                }
              );
            });
          }
        }
      });
    }
  }, [merchantId])

  return (
    <>
      <MDBContainer className="menu-page-container">
        <div className="store-header">
          <StoreHeader shipping={shipping} outlet={outletInfo} loading={outletInfoLoading} />
          {announcementList.length > 0 && 
            <div className="relative" style={{ height: '70px' }}>
              <Announcement announcement={announcementList} loading={outletInfoLoading} />
            </div>
          }
        </div>
        <CategoryBar categories={categoryList} loading={categoryLoading} />
        <MenuListing menus={menuList} loading={menuLoading} carts={cartInfo} />
        <></>
        <div className="flex justify-center">
        {(addCartLoading || (cartCounts > 0 && cartInfo?.cart.length > 0)) &&
          <MDBRow className="button-container --full-with-bg" style={{ background: 'transparent' }}>
            <Button onClick={() => handleToCart()}>
              <div className="flex justify-between items-center">
                <div className="col-2">
                  <label className="quantity-circle"><span>x </span>{cartCounts ? cartCounts : 1}</label>
                </div>
                <div className="col-8">
                  <label className="--view-cart">View Your Cart</label>
                </div>
                <div className="col-2">
                  {!addCartLoading ?
                    <label className="--cart-price">{cartInfo && parseFloat(cartInfo.subtotal).toFixed(2)}</label>
                  : 
                    <Icon
                      icon="line-md:loading-twotone-loop"
                      width="25px"
                      height="25px"
                      color="#fdfdff"
                      className="ms-3 mb-1"
                    />
                  }
                </div>
              </div>
            </Button>
          </MDBRow>
        }
        </div>
      </MDBContainer>
      {isOpenCartModal && <AddToCart />}
      {isOpenChangeCartModal && <ConfirmationModal cart={cartInfo} />}
      {isOpenReOrderModal && <ReOrder />}
    </>
  );
}
